@font-face {
  font-family: bithigh;
  src: url("bithigh.a4ebf3ff.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

body, html {
  -webkit-text-size-adjust: 100%;
  height: 100%;
  margin: 0;
  font-family: bithigh;
}

body {
  letter-spacing: 4px;
  background-color: #000;
  font-family: bithigh, sans-serif;
  overflow-x: hidden;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-group {
  justify-content: space-between;
  gap: 10px;
  display: flex;
}

input[type="text"], input[type="password"] {
  color: #0f0;
  background-color: #00285099;
  border: 1px solid #0f0;
  border-radius: 5px;
  flex: 1;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: inset 0 0 10px #00ff0080;
}

.footer {
  color: #00ff00bc;
  text-align: center;
  background-color: #0000;
  width: 100%;
  height: 25px;
  margin: 0 auto;
  padding: 10px;
  font-size: .8rem;
  position: absolute;
  bottom: 5px;
}

button[type="submit"] {
  color: #000;
  cursor: pointer;
  background-color: #00ff00b3;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  transition: all .3s;
  box-shadow: 0 0 10px #00ff00b3;
}

button[type="submit"]:hover {
  color: #000;
  background-color: #0f0;
}

p {
  color: #0f0;
}

h2 {
  color: #0f0;
  text-align: center;
}

h3 {
  color: #0f0;
  text-align: center;
  padding: 40px 10px 10px;
  font-size: 3rem;
}

h4 {
  color: #0f0;
  text-align: center;
  background-color: #fdfe0033;
  padding: 10px 0 12px;
  font-size: 1.5rem;
}

video {
  will-change: transform;
  backface-visibility: hidden;
  transform: translateZ(0);
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

#scan-alert-modal {
  z-index: 9999;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

#scan-alert-modal .modal-content {
  color: #0f0;
  text-align: center;
  background-color: #006400bf;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: 80%;
  max-width: 400px;
  margin: 15% auto;
  padding: 20px;
}

#scanButton {
  color: #000b35;
  background-color: #72ff00cc;
}

#fulfillButton {
  color: #000b35;
  background-color: #ff7100cc;
}

#logOut {
  box-sizing: border-box;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #0f0;
  cursor: pointer;
  z-index: 999999;
  float: left;
  background: #ff000096;
  border: 2px solid red;
  border-radius: 1px;
  width: 100%;
  max-width: 100%;
  margin: 10px 0 50px;
  padding: 10px;
  font-family: bithigh, Arial, sans-serif;
  font-size: 14px;
  transition: all .3s;
  position: relative;
  box-shadow: 0 0 5px #ff000096;
}

#logOut:hover {
  color: #000;
  background: #0f0;
  box-shadow: 0 0 10px #00ff00b3;
}

#logOut:active {
  transform: scale(.98);
  box-shadow: 0 0 2px #00ff0080;
}

.trader-name {
  text-align: center;
  width: 100%;
  max-width: 100%;
  margin-top: 5%;
  overflow: hidden;
}

.traderTitNam {
  color: #0f0;
  text-align: center;
  margin-top: 60px;
  padding: 3px;
}

#holdings-container {
  margin: 20px;
  display: block;
}

.holding-item {
  contain: content;
  background-color: #006400bf;
  border: 4px solid #0f0;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px;
  font-size: 16px;
}

.holding-item p {
  margin: 10px 0;
  padding: 5px;
}

.holding-item:nth-child(odd) p {
  background-color: #00ff001a;
}

.holding-item:nth-child(2n) p {
  background-color: #0f03;
}

.holding-item strong {
  background-color: #00ff001a;
  width: 130px;
  margin-right: 10px;
  padding: 5px;
  font-weight: bold;
  display: inline-block;
}

.holding-item:nth-child(2n) strong {
  background-color: #0f03;
}

#stock-holdings-container {
  box-sizing: border-box;
  background-color: #017cfbcc;
  border: 3px solid #2441c0e4;
  border-radius: 5px;
  width: 100%;
  margin: 0 0 15px;
  padding: 10px;
  display: block;
}

#stock-holdings-container h2 {
  color: #01fbf4cc;
  text-align: center;
  margin: 0 0 10px;
  font-size: 1.2rem;
}

.holding-stocks {
  background-color: #017cfbcc;
  border: 1px solid #01fbf4cc;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 8px;
  position: relative;
}

.stock-header {
  border-bottom: 1px solid #01fbf480;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding-bottom: 4px;
  display: flex;
}

.stock-name {
  color: #0f0;
  content-align: center;
  width: 100%;
  font-size: .95rem;
  font-weight: bold;
}

.stock-symbol {
  color: #01fbf4cc;
  background-color: #081b6fe4;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: .8rem;
}

.stock-details {
  justify-content: space-between;
  display: flex;
}

.shorts-container {
  border-top: 1px dashed #ccc;
  margin-top: 10px;
  padding-top: 8px;
}

.short-position-detail {
  background-color: #dc35451a;
  border-left: 3px solid #dc3545;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 8px;
}

.short-header {
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.short-label {
  color: #3dd202ba;
  background-color: #dc3545;
  border-radius: 3px;
  margin-right: 8px;
  padding: 2px 6px;
  font-size: 11px;
  font-weight: bold;
}

.shorter-name {
  font-size: 14px;
  font-weight: bold;
}

.short-info {
  justify-content: space-between;
  margin-bottom: 5px;
  display: flex;
}

.short-quantity, .short-value {
  color: #fee000e3;
  font-size: 13px;
}

.short-reason {
  color: #fdfe00ee;
  border-top: 1px solid #0000001a;
  margin-top: 5px;
  padding-top: 5px;
  font-size: 13px;
  font-style: italic;
}

.detail-item {
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 2px;
  display: flex;
}

.detail-label {
  color: #01fbf4cc;
  text-align: center;
  background-color: #0139fb66;
  border-radius: 2px 2px 0 0;
  width: 100%;
  padding: 2px 0;
  font-size: .7rem;
}

.detail-value {
  color: #0f0;
  text-align: center;
  background-color: #0139fb33;
  border-radius: 0 0 2px 2px;
  width: 100%;
  padding: 2px 0;
  font-size: .85rem;
}

.holding-stocks span {
  color: #0f0;
  margin: 5px 0;
  padding: 5px;
  display: block;
}

.holding-stocks span:nth-child(odd) {
  background-color: #0139fb66;
}

.holding-stocks span:nth-child(2n) {
  background-color: #0139fb33;
}

.holding-stocks span strong {
  background-color: #0139fbcc;
  width: 130px;
  margin-right: 10px;
  padding: 5px;
  font-weight: bold;
  display: inline-block;
}

.holding-stocks span:nth-child(2n) strong {
  background-color: #017cfbcc;
}

.trader-popup-overlay {
  z-index: 9999;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.trader-popup-content {
  background: #00051dca;
  border: 2px solid #3dd202ba;
  border-radius: 5px;
  width: 90%;
  max-width: 600px;
  padding: 20px;
  position: relative;
}

#recipientList {
  color: #0f0;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.recipient {
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin-bottom: 5px;
  display: flex;
}

.recipient input[type="checkbox"] {
  margin-right: 10px;
}

#messagePopup {
  z-index: 1000;
  text-align: center;
  background-color: #000209eb;
  border-radius: 5px;
  width: 90%;
  max-width: 90%;
  padding: 20px;
  font-family: bithigh, sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #00000080;
}

#messagePopup select, #messagePopup textarea {
  color: #0f0;
  background-color: #000c;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: 90%;
  max-width: 90%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  box-shadow: inset 0 0 10px #00ff00b3;
}

#messagePopup h2, #messagePopup p {
  color: #0f0;
  margin: 10px 0;
}

#messagePopup button {
  cursor: pointer;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: calc(50% - 10px);
  margin: 5px;
  padding: 10px;
  font-size: 16px;
  transition: all .3s;
  box-shadow: 0 0 10px #00ff00b3;
}

#messagePopup button:hover {
  color: #000;
  background-color: #0f0;
}

#messageButton {
  color: #0f0;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1000;
  background-color: #000000e6;
  border-radius: 5px;
  width: 98%;
  max-width: 600px;
  height: auto;
  margin: 10px 0;
  font-family: bithigh, sans-serif;
  font-size: 1rem;
  transition: all .3s;
  position: relative;
}

#messageButton:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px #00ff00e6;
}

.received-message {
  color: #fff;
  z-index: 1000;
  background-color: #000000b3;
  border-radius: 5px;
  padding: 10px;
  position: fixed;
  top: 20px;
  right: 20px;
}

#acceptCommunity {
  color: #00ff24b9;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  background-color: #0f03;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: 48%;
  margin: 10px 0;
  padding: 10px;
  font-family: bithigh, Arial, sans-serif;
  font-size: 14px;
  transition: all .3s;
  box-shadow: 0 0 5px #00ff0080;
}

#declineCommunity, #cancelSelectionSubsidy, #cancelSelectionLBO {
  color: #f009;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  background-color: #f003;
  border: 2px solid red;
  border-radius: 5px;
  width: 48%;
  margin: 10px 0;
  padding: 10px;
  font-family: bithigh, Arial, sans-serif;
  font-size: 12px;
  transition: all .3s;
  box-shadow: 0 0 5px #ff0000a2;
}

.modal-steal {
  z-index: 1000;
  background-color: #000000d9;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-steal-content {
  color: #0f0;
  text-align: left;
  background-color: #111;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  margin: 10% auto;
  padding: 20px;
  font-family: bithigh, sans-serif;
  box-shadow: 0 0 10px #0f0c, 0 0 50px #0f06;
}

#closeMoodPopup {
  color: #0f0;
  cursor: pointer;
  background-color: #ff0000b3;
  font-size: 1.5rem;
  transition: transform .3s, color .3s;
}

.modal-steal-close {
  float: right;
  color: #0f0;
  cursor: pointer;
  font-size: 1.5rem;
  transition: transform .3s, color .3s;
}

.modal-steal-close:hover {
  color: #3aff3a;
  transform: scale(1.2);
}

.modal-steal-item-list {
  scrollbar-width: thin;
  scrollbar-color: #00ff0080 #00000080;
  background-color: #000c;
  border: 1px solid #00ff0080;
  border-radius: 5px;
  width: 100%;
  height: calc(100% - 40px);
  max-height: 600px;
  margin: 10px 0;
  padding: 10px;
  overflow-y: auto;
}

.modal-steal-item-list::-webkit-scrollbar {
  width: 8px;
}

.modal-steal-item-list::-webkit-scrollbar-thumb {
  background: #00ff0080;
  border-radius: 4px;
}

.modal-steal-item-list::-webkit-scrollbar-track {
  background: #00000080;
}

.modal-steal-item {
  cursor: pointer;
  background-color: #000000b3;
  border: 1px solid #0f0;
  border-radius: 5px;
  margin: 5px 0;
  padding: 10px;
  transition: background-color .3s, transform .3s;
}

.modal-steal-item:hover {
  will-change: transform;
  background-color: #0f03;
  transform: translateX(5px);
}

.give-away-button, #confirmSelectionSubsidy, #confirmSelectionLBO {
  color: #00ff2499;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  background-color: #0f03;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  font-family: bithigh, Arial, sans-serif;
  font-size: 12px;
  transition: all .3s;
  box-shadow: 0 0 5px #00ff0080;
}

.give-away-button:hover {
  color: #000;
  background-color: #0f0;
  box-shadow: 0 0 10px #00ff00b3;
}

.give-away-button:active {
  transform: scale(.98);
  box-shadow: 0 0 2px #00ff0080;
}

#confirmGiveAwayPopup {
  z-index: 1000;
  color: #0f0;
  text-align: center;
  background-color: #000000d9;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
  max-width: 400px;
  height: auto;
  padding: 20px;
  font-family: bithigh, sans-serif;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#confirmGiveAwayPopup h2 {
  color: #0f0;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.5rem;
}

#confirmGiveAwayPopup p {
  color: #0f0;
  margin-bottom: 20px;
  font-size: 1rem;
}

#confirmGiveAwayPopup .popup-buttons {
  justify-content: space-between;
  gap: 10px;
  display: flex;
}

#confirmGiveAwayPopup button {
  cursor: pointer;
  border: 2px solid #0f0;
  border-radius: 5px;
  flex: 1;
  padding: 10px 20px;
  font-size: 1rem;
  transition: all .3s;
}

#confirmGiveAwayPopup button#confirmGiveAway {
  color: #000;
  background-color: #00ff00b3;
}

#confirmGiveAwayPopup button#confirmGiveAway:hover {
  color: #000;
  background-color: #0f0;
  box-shadow: 0 0 10px #00ff00b3;
}

#confirmGiveAwayPopup button#cancelGiveAway {
  color: #000;
  background-color: #ff0000b3;
}

#confirmGiveAwayPopup button#cancelGiveAway:hover {
  color: #000;
  background-color: red;
  box-shadow: 0 0 10px #ff0000b3;
}

.hiddenST, .hiddenMU, .hiddenRS, .hiddenCM, .hiddenSubsidy, .hiddenLBO {
  display: none;
}

#userSelectSubsidy, #userSelectLBO {
  color: #0f0;
  background-color: #000;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: 95%;
  max-width: 95%;
  height: 5%;
  max-height: 5%;
  margin-bottom: 10px;
  padding: 10px;
}

@media screen and (width <= 768px) {
  .give-away-button {
    padding: 8px;
    font-size: 12px;
  }
}

#points-container {
  text-align: center;
  background-color: #002400bf;
  border-top: 1px solid #ff8700db;
  border-bottom: 2px solid #ff8700db;
  width: 100%;
  margin-top: 10px;
  padding: 2px 0;
  display: block;
}

#total-points {
  color: #0f0;
  padding: 1px 0;
  font-size: 24px;
  font-weight: bold;
}

.zolPop {
  text-align: left;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: .7rem;
  line-height: 1.5rem;
  display: flex;
}

.led-board {
  margin: 0 auto;
  font-size: 0;
  line-height: 0;
}

.led {
  display: inline-block;
}

.total-pointsB {
  background-color: #1cd20266;
  border-radius: 4px;
  padding: 10px;
  font-size: 1.8rem;
  margin: 5px 0 !important;
}

.item-points {
  color: #0f0;
  margin: 5px 0;
  padding-bottom: 5px;
  font-size: 14px;
  display: block;
}

.qr-code-item img {
  background-color: #006400bf;
  border: 1px solid #0f0;
  width: 150px;
  margin: 10px auto;
  padding: 5px;
  display: block;
}

#qr-codes-container {
  text-align: center;
  border-top: 2px dashed #0f0;
  margin-top: 30px;
  padding: 10px 0;
}

.qr-code-item {
  text-align: center;
  color: #0f0;
  vertical-align: top;
  background-color: #006400bf;
  border: 2px solid #0f0;
  width: 250px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: inline-block;
}

.qr-code-item p {
  margin: 10px 0;
  font-size: 14px;
}

.qr-code-item img {
  cursor: pointer;
  background-color: #006400bf;
  border: 1px solid #0f0;
  width: 150px;
  margin: 10px auto;
  padding: 5px;
  display: block;
}

.modal {
  z-index: 9999;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content {
  color: #0f0;
  background-color: #006400bf;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: 80%;
  max-width: 400px;
  margin: 15% auto;
  padding: 20px;
  font-family: bithigh, Arial, sans-serif;
}

.modal-content h2 {
  color: #0f0;
  margin-top: 0;
}

#poolNameInput, #userSearchInput {
  box-sizing: border-box;
  width: 90%;
  max-width: 90%;
  height: 50px;
  max-height: 50px;
  line-height: 50px;
}

.modal-content input, .modal-content select {
  color: #0f0;
  background-color: #00640080;
  border: 1px solid #0f0;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 8px;
  font-size: 14px;
  display: block;
}

.modal-content button {
  color: #000;
  cursor: pointer;
  background-color: #00ff00b3;
  border: none;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  font-size: 14px;
  display: block;
}

.modal-content button:hover {
  background-color: #0f0;
}

.modal-buttons {
  margin-top: 20px;
}

.modal-btn {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin: 10px;
  padding: 10px 20px;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.close {
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: all .3s;
  position: absolute;
  top: 15px;
  right: 35px;
}

.close:hover, .close:focus {
  color: #bbb;
  cursor: pointer;
  text-decoration: none;
}

.info-icon {
  cursor: pointer;
  text-align: center;
  color: #00ff00d8;
  background-color: #000209eb;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  padding: 5px;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  position: relative;
}

.input-group input {
  flex: 1;
}

.info-icon:hover:after {
  content: attr(title);
  color: #fff;
  white-space: normal;
  z-index: 1000;
  text-align: left;
  background: #000824d4;
  border-radius: 5px;
  width: 50vw;
  max-width: 50vw;
  padding: 25px;
  font-size: 12px;
  position: absolute;
  top: -5px;
  right: 25px;
}

.modal, .info-modal {
  z-index: 1000;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content, .info-modal-content {
  text-align: left;
  background-color: #021a6d99;
  border: 1px solid #888;
  width: 70%;
  max-width: 500px;
  margin: 150px auto;
  padding: 20px;
}

.info-modal-content {
  text-align: center;
  width: 80%;
  max-width: 500px;
}

.info-modal-content button {
  margin-top: 20px;
}

.zeconComp {
  text-align: left;
}

#qr-code-modal-details {
  color: #0f0;
  text-align: center;
  margin-top: 20px;
}

#qr-code-modal-details p {
  margin: 10px 0;
  font-size: 18px;
}

@keyframes blinkRede {
  0% {
    color: #c200ff;
    background-color: #f5fc00;
  }

  50% {
    color: #d27802;
    background-color: #00ffed;
  }

  100% {
    color: #c200ff;
    background-color: #f5fc00;
  }
}

#items-list {
  margin-bottom: 20px;
}

.marketBorderHr {
  color: #2f0;
  height: 3px;
}

#items-list tr:last-child {
  border-bottom: none;
}

#items-table thead tr th:nth-child(8), #items-table thead tr th:nth-child(9) {
  display: none;
}

.marketBanner {
  text-align: center;
  z-index: 999;
  width: 100%;
  max-width: 100vw;
  height: auto;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.marketBanner img {
  width: 100%;
  height: auto;
  display: block;
}

:root {
  --glow-color: #f8c3d9;
  --glow-spread-color: #2300ff82;
  --enhanced-glow-color: #6effb5;
  --btn-color: #b902d2cc;
  --hover-bg-color: #ff7900;
  --hover-text-color: #6effb5;
}

#goToMarketBtn {
  box-sizing: border-box;
  letter-spacing: 4px;
  background-color: var(--btn-color);
  width: 100%;
  max-width: 100vw;
  color: var(--glow-color);
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  z-index: 1002;
  border: 2px solid var(--glow-color);
  box-shadow: 0 0 1em .25em var(--glow-color), 0 0 4em 1em var(--glow-spread-color), inset 0 0 .75em .25em var(--glow-color);
  text-shadow: 0 0 .5em var(--glow-color);
  border-radius: 3px;
  outline: none;
  padding: 10px;
  font-family: bithigh, sans-serif;
  font-size: 1rem;
  transition: all .3s;
  position: fixed;
  bottom: 0;
}

#goToMarketBtn:after {
  pointer-events: none;
  content: "";
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: .7;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 120%;
  left: 0;
  transform: perspective(1.5em)rotateX(35deg)scale(1, .6);
}

#goToMarketBtn:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
  box-shadow: 0 0 1em .25em var(--glow-color), 0 0 4em 2em var(--glow-spread-color), inset 0 0 .75em .25em var(--glow-color);
}

#goToMarketBtn:active {
  box-shadow: 0 0 .6em .25em var(--glow-color), 0 0 2.5em 2em var(--glow-spread-color), inset 0 0 .5em .25em var(--glow-color);
}

#goToDarkPoolMarketBtn {
  box-sizing: border-box;
  letter-spacing: 4px;
  background-color: var(--btn-color);
  width: 100%;
  max-width: 100vw;
  color: var(--glow-color);
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  z-index: 999;
  border: 2px solid var(--glow-color);
  box-shadow: 0 0 1em .25em var(--glow-color), 0 0 4em 1em var(--glow-spread-color), inset 0 0 .75em .25em var(--glow-color);
  text-shadow: 0 0 .5em var(--glow-color);
  border-radius: 3px;
  outline: none;
  padding: 10px;
  font-family: bithigh, sans-serif;
  font-size: 1rem;
  transition: all .3s;
  position: fixed;
  bottom: 0;
}

#communityInterface {
  color: #0f0;
  text-align: center;
  z-index: 1000;
  background-color: #0000004d;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 99%;
  max-width: 100%;
  padding: 8px;
  font-family: bithigh, sans-serif;
  font-size: .7rem;
  transition: all .3s;
  display: flex;
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 10px #00ff00b3, 0 0 20px #00ff00b3;
}

#communityInterface h2 {
  color: #0f0;
  text-shadow: 0 0 5px #0f0c;
  margin: 5px 0;
  font-size: .8rem;
}

#communityInterface .community-timer {
  color: #0f0;
  text-shadow: 0 0 5px #0f0;
  text-align: center;
  background-color: #0007;
  border-radius: 3px;
  width: 100%;
  padding: 4px;
  font-size: .9rem;
  box-shadow: 0 0 8px #00ff0080;
}

#leaveCommunityBtn {
  color: red;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #ff000056;
  border: 1px solid red;
  border-radius: 3px;
  width: 100%;
  padding: 6px;
  font-size: .75rem;
  transition: all .3s;
}

#leaveCommunityBtn:hover {
  color: #000;
  background-color: #72ff00cc;
  box-shadow: 0 0 8px #ff0000b3;
}

#leaveCommunityBtn:active {
  transform: scale(.95);
}

#communityTimer {
  color: #0f0;
  text-shadow: 0 0 5px #0f0;
  text-align: center;
  z-index: 1001;
  background-color: #00000080;
  border-radius: 5px;
  width: 99%;
  max-width: 100%;
  padding: 8px;
  font-size: .8rem;
  transition: all .3s;
  position: fixed;
  top: calc(100px + 1em);
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 10px #00ff0080;
}

@media screen and (width <= 600px) {
  #communityInterface {
    width: 99%;
    padding: 6px;
    font-size: .75rem;
    bottom: 50px;
    transform: translateX(-50%);
  }

  #communityInterface h2 {
    font-size: .8rem;
  }

  #leaveCommunityBtn {
    padding: 5px;
    font-size: .75rem;
  }

  #communityTimer {
    padding: 6px;
    font-size: .6rem;
    top: calc(60px + 1em);
  }

  #trader-name {
    text-align: center;
    width: 95%;
    margin: 5% 0 0;
    overflow: hidden;
  }
}

#goToDarkPoolMarketBtn:after {
  pointer-events: none;
  content: "";
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: .7;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 120%;
  left: 0;
  transform: perspective(1.5em)rotateX(35deg)scale(1, .6);
}

#goToDarkPoolMarketBtn:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
  box-shadow: 0 0 1em .25em var(--glow-color), 0 0 4em 2em var(--glow-spread-color), inset 0 0 .75em .25em var(--glow-color);
}

#goToDarkPoolMarketBtn:active {
  box-shadow: 0 0 .6em .25em var(--glow-color), 0 0 2.5em 2em var(--glow-spread-color), inset 0 0 .5em .25em var(--glow-color);
}

#goToTradeBtn {
  --glow-color: #f3f69fd1;
  --glow-spread-color: #00ff00c7;
  --btn-color: #ff006a;
  --hover-bg-color: #ff00e6;
  --hover-text-color: #0303d2;
  box-sizing: border-box;
  letter-spacing: 4px;
  background: var(--btn-color);
  width: 50%;
  max-width: 100vw;
  color: var(--glow-color);
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  z-index: 999;
  border: 2px solid var(--glow-color);
  box-shadow: 0 0 1em .15em var(--glow-color), 0 0 2em .5em var(--glow-spread-color), inset 0 0 .55em .15em var(--glow-color), 0 -.5em 1em .1em #ffffff80;
  text-shadow: 0 0 .15em var(--glow-color);
  border-radius: 3px;
  outline: none;
  padding: 10px;
  font-family: bithigh, sans-serif;
  font-size: 1rem;
  transition: all .3s;
  position: fixed;
  bottom: 0;
  left: 0;
}

#goToTradeBtn:after {
  pointer-events: none;
  content: "";
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: .5;
  width: 100%;
  height: 50%;
  position: absolute;
  top: 100%;
  left: 0;
  transform: perspective(1.5em)rotateX(35deg)scale(1, .6);
}

#goToTradeBtn:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
  box-shadow: 0 0 1em .25em var(--glow-color), 0 0 2em 1em var(--glow-spread-color), inset 0 0 .75em .25em var(--glow-color), 0 -.5em 1em .1em #ffffff80;
}

#goToTradeBtn:active {
  box-shadow: 0 0 .6em .25em var(--glow-color), 0 0 2em 1em var(--glow-spread-color), inset 0 0 .5em .25em var(--glow-color), 0 -.5em 1em .1em #ffffff80;
}

#filterButton {
  --glow-color: #f3f69fd1;
  --glow-spread-color: #00ff00c7;
  --btn-color: #70f;
  --hover-bg-color: #00ffc9;
  --hover-text-color: #0303d2;
  box-sizing: border-box;
  letter-spacing: 4px;
  background: var(--btn-color);
  width: 50%;
  max-width: 100vw;
  color: var(--glow-color);
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  z-index: 999;
  border: 2px solid var(--glow-color);
  box-shadow: 0 0 1em .15em var(--glow-color), 0 0 2em .5em var(--glow-spread-color), inset 0 0 .55em .15em var(--glow-color), 0 -.5em 1em .1em #ffffff80;
  text-shadow: 0 0 .15em var(--glow-color);
  border-radius: 3px;
  outline: none;
  padding: 10px;
  font-family: bithigh, sans-serif;
  font-size: 1rem;
  transition: all .3s;
  position: fixed;
  bottom: 0;
  right: 0;
}

#filterButton:after {
  pointer-events: none;
  content: "";
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: .5;
  width: 100%;
  height: 50%;
  position: absolute;
  top: 100%;
  right: 0;
  transform: perspective(1.5em)rotateX(35deg)scale(1, .6);
}

#filterButton:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
  box-shadow: 0 0 1em .25em var(--glow-color), 0 0 2em 1em var(--glow-spread-color), inset 0 0 .75em .25em var(--glow-color), 0 -.5em 1em .1em #ffffff80;
}

#filterButton:active {
  box-shadow: 0 0 .6em .25em var(--glow-color), 0 0 2em 1em var(--glow-spread-color), inset 0 0 .5em .25em var(--glow-color), 0 -.5em 1em .1em #ffffff80;
}

#redeemButton {
  --glow-color: #00ff00bc;
  --glow-spread-color: #00ff00c7;
  --btn-color: #f70;
  --hover-bg-color: #00ffed;
  --hover-text-color: #d27802;
  box-sizing: border-box;
  letter-spacing: 4px;
  background: var(--btn-color);
  width: 50%;
  max-width: 100vw;
  color: var(--glow-color);
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  z-index: 999;
  border: 2px solid var(--glow-color);
  box-shadow: 0 0 1em .15em var(--glow-color), 0 0 2em .5em var(--glow-spread-color), inset 0 0 .55em .15em var(--glow-color), 0 -.5em 1em .1em #ffffff80;
  text-shadow: 0 0 .15em var(--glow-color);
  border-radius: 3px;
  outline: none;
  padding: 10px;
  font-family: bithigh, sans-serif;
  font-size: 1rem;
  transition: all .3s;
  position: fixed;
  bottom: 0;
  right: 0;
}

#redeemButton:after {
  pointer-events: none;
  content: "";
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: .5;
  width: 100%;
  height: 50%;
  position: absolute;
  top: 100%;
  left: 0;
  transform: perspective(1.5em)rotateX(35deg)scale(1, .6);
}

#redeemButton:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
  box-shadow: 0 0 1em .25em var(--glow-color), 0 0 2em 1em var(--glow-spread-color), inset 0 0 .75em .25em var(--glow-color), 0 -.5em 1em .1em #ffffff80;
}

#redeemButton:active {
  box-shadow: 0 0 .6em .25em var(--glow-color), 0 0 2em 1em var(--glow-spread-color), inset 0 0 .5em .25em var(--glow-color), 0 -.5em 1em .1em #ffffff80;
}

@media screen and (width <= 768px) {
  #goToTradeBtn, #filterButton, #redeemButton {
    height: 50px;
  }

  #goToTradeBtn {
    left: 0;
    right: initial;
  }

  #redeemButton {
    right: 0;
    left: initial;
  }
}

#filter-section {
  z-index: 9999;
  background-color: #000000e6;
  border-radius: 10px;
  width: 95%;
  max-width: 95%;
  padding: 20px;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 20px #00ff00b3, 0 0 30px #00ff00b3, 0 0 40px #00ff00b3, 0 0 50px #00ff00b3;
}

#filter-section input, #filter-section select {
  color: #0f0;
  background-color: #000c;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: 90%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  display: block;
  box-shadow: inset 0 0 10px #00ff00b3;
}

#filter-section button {
  color: #0f0;
  cursor: pointer;
  background-color: #000000e6;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  transition: all .3s;
  display: block;
  box-shadow: 0 0 10px #00ff00b3;
}

#filter-section button:hover {
  color: #000;
  background-color: #00ff00e6;
  box-shadow: 0 0 20px #00ff00e6;
}

table {
  border-collapse: collapse;
  color: #0f0;
  text-align: center;
  background-color: #00000080;
  background-image: radial-gradient(circle, #fff3 10%, #0000 10%);
  background-size: 10px 10px;
  width: 100%;
  margin: 0 auto;
  font-family: bithigh;
  display: block;
}

th, td {
  word-wrap: break-word;
  background-color: #0000;
  padding: 5px;
}

th {
  background-color: #0f1e09;
}

tr {
  justify-content: center;
  align-items: center;
  height: calc(90vh - 5px);
  display: flex;
}

@media screen and (width <= 2600px) {
  thead {
    display: none;
  }

  th, td {
    text-align: center;
    color: #0f0;
    padding: 5px;
    font-size: 14px;
    display: block;
  }

  table, tbody, tr, td {
    width: 100%;
    font-size: 14px;
    display: block;
  }

  td {
    text-align: center;
    box-sizing: border-box;
    background-color: #0066004d;
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 14px;
    display: block;
    position: relative;
  }

  td:before {
    content: attr(data-title);
    color: #fff;
    text-align: center;
    z-index: 1;
    background-color: #00ff0080;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px;
    font-size: .9rem;
    font-weight: bold;
    display: block;
    position: relative;
  }

  td[data-title="Points"]:before {
    content: "Value";
  }

  td[data-title="Points"], td[data-title="Quantity"], td[data-title="Location"], td[data-title="#Shares"], td[data-title="Owner"] {
    float: left;
    box-sizing: border-box;
    width: 50%;
    margin: 0 auto;
    font-size: .9rem;
  }

  .stock-row {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .stock-row td:before {
    content: attr(data-title);
    color: #ff006bcf;
    text-align: center;
    z-index: 1;
    background-color: #007bff80;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 5px;
    font-size: .9rem;
    font-weight: bold;
    display: block;
    position: relative;
  }

  .styled-select {
    background-color: #000c;
    border: 2px solid #0f0;
    border-radius: 5px;
    width: 100%;
    max-width: 300px;
    margin: 10px auto;
    display: inline-block;
    position: relative;
    box-shadow: 0 0 10px #00ff0080;
  }

  .styled-select select {
    color: #0f0;
    appearance: none;
    cursor: pointer;
    background-color: #0000;
    border: none;
    outline: none;
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    font-family: bithigh, sans-serif;
    font-size: 16px;
  }

  .styled-select:after {
    content: "▼";
    color: #0f0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  .styled-select:hover {
    box-shadow: 0 0 15px #00ff00b3;
  }

  .stock-row td[data-title="#Shares"], .stock-row td[data-title="Description"], .stock-row td[data-title="Owner"] {
    color: #ff006b;
  }

  @keyframes backgroundFadeITBY {
    0% {
      background-color: #27ff00;
    }

    50% {
      background-color: #27ff0080;
    }

    100% {
      background-color: #27ff00;
    }
  }

  @keyframes backgroundFadeITBYS {
    0% {
      background-color: #7b96f3;
    }

    50% {
      background-color: #0139fb80;
    }

    100% {
      background-color: #7b96f3;
    }
  }

  .stock-row td {
    background-color: #0139fb4d;
  }

  td[data-title="Company Name"], td[data-title="Value"], td[data-title="Shorted By"] {
    clear: both;
    color: #ff006b;
    margin: 0 auto;
    padding: 0;
    font-size: 1.4rem;
  }

  td[data-title="Ask"], td[data-title="Bid"], td[data-title="Short"] {
    float: left;
    box-sizing: border-box;
    background: #01fbf4cc;
    width: 50%;
    font-size: 1rem;
    animation: 3s infinite backgroundFadeITBYS;
  }

  td[data-title="Ask"]:before, td[data-title="Bid"]:before, td[data-title="Short"]:before {
    content: attr(data-title);
    visibility: hidden;
    height: 0;
  }

  .space-short {
    margin: 0;
  }

  .name-short, .shares-short, .value-short {
    box-sizing: border-box;
    background: #021a6d99;
    align-content: center;
    width: 33.33%;
    height: 50px;
    padding: 2px;
    font-size: .8rem;
  }

  #items-list td[data-title="Shorted By"] table tr {
    margin-top: 0;
  }

  td[data-title="Shorted By"] table:last-child {
    margin-bottom: 80px;
  }

  td[data-title="Shorted By"] table {
    float: left;
    width: 100%;
    max-width: 100%;
    height: 50px;
    margin: 0 0 10px;
    display: block;
    position: relative;
  }

  td[data-title="Shorted By"] td:before {
    display: none;
  }

  td[data-title="Shorted By"] td {
    position: inherit;
    float: left;
    display: block;
  }

  td[data-title="Trade"], td[data-title="Buy"] {
    float: left;
    box-sizing: border-box;
    background: #27ff00;
    width: 50%;
    font-size: 1rem;
    animation: 3s infinite backgroundFadeITBY;
  }

  td[data-title="Trade"]:before, td[data-title="Buy"]:before {
    content: attr(data-title);
    visibility: hidden;
    height: 0;
  }

  td[data-title="Item"] {
    clear: both;
    margin: 0 auto;
    font-size: 1.4rem;
  }

  td[data-title="Description"], td[data-title="Redeem"] {
    clear: both;
    margin: 0 auto;
    font-size: 1rem;
  }
}

.buyButton {
  color: #0f0;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  background: #060;
  border: 1px solid #0f0;
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.buyButton:hover {
  color: #0303d2;
  background: #0f0;
}

.stockButton {
  color: #ff006a;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  background: #0139fb80;
  border: 1px solid #01fbf4cc;
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.stockButton:hover {
  color: #0303d2;
  background: #0f0;
}

.tradeButton {
  color: #0f0;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  background: #060;
  border: 1px solid #0f0;
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.tradeButton:hover {
  color: #0303d2;
  background: #0f0;
}

#stock-list tr:last-child {
  border-bottom: none;
}

#stock-list td {
  word-wrap: break-word;
  background-color: #0000;
  padding: 5px;
}

#stock-list td[data-title="Stock"] {
  margin: 0 auto;
  font-size: 1.4rem;
}

#stock-list td[data-title="Description"] {
  clear: both;
  margin: 0 auto;
  font-size: 1rem;
}

#stock-list td[data-title="Shares"], #stock-list td[data-title="Price"] {
  float: left;
  box-sizing: border-box;
  width: 50%;
  margin: 0 auto;
  font-size: .9rem;
}

#stock-list td[data-title="Trade"] {
  float: left;
  box-sizing: border-box;
  background: #017cfbcc;
  width: 50%;
  font-size: 1rem;
  animation: 3s infinite backgroundFadeITBY;
}

.sharesTrad {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.sharesTrad strong {
  text-align: center;
  width: 100%;
  min-width: 97%;
  margin-bottom: 5px;
  display: block;
}

.sharesTrad span {
  text-align: center;
  width: 100%;
  display: block;
}

#shorted-stocks-container {
  height: 100%;
  margin-bottom: 50px;
}

#stock-holdings-container, #shorted-stocks-container {
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

#short-positions-container {
  width: 100%;
  margin-bottom: 50px;
}

#shorted-stocks-list {
  background: #f8923cd9;
  border: 3px solid #ff0000db;
  width: 90%;
}

.close-short-button {
  color: #000;
  cursor: pointer;
  background-color: #ff0000e3;
  border: 2px solid #000;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  font-family: bithigh, Arial, sans-serif;
  font-size: 16px;
  transition: all .3s;
  display: block;
  box-shadow: 0 0 5px #ffa50080;
}

.close-short-button:hover {
  background-color: #00ff0079;
  box-shadow: 0 0 10px #ffa500b3;
}

#steal-button-container, #delete-portfolio-button-container, #ransom-button-container, #create-community-button-container, #LBOButton-button-container, #SubsidyButton-button-container {
  text-align: center;
  z-index: 1000;
  margin-top: 20px;
  display: none;
  position: relative;
}

#userSearchInput, #poolNameInput {
  color: #0f0;
  background-color: #000c;
  border: 1px solid #00ff0080;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1rem;
  box-shadow: inset 0 0 5px #00ff0080;
}

.popup-user-list, #userList {
  scrollbar-width: thin;
  scrollbar-color: #00ff0080 #00000080;
  background-color: #000000e6;
  border: 1px solid #00ff0080;
  border-radius: 5px;
  max-height: 300px;
  padding: 10px;
  overflow-y: auto;
}

#userList label {
  cursor: pointer;
  margin-bottom: 5px;
  font-size: .8rem;
  display: block;
}

#createCommunityButton {
  color: #0f0;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1000;
  background-color: #000000e6;
  border-radius: 3px;
  width: 98%;
  max-width: 600px;
  height: auto;
  font-family: bithigh, sans-serif;
  font-size: 1rem;
  transition: all .3s;
  position: relative;
}

#stealButton, #deletePortfolioButton, #ransomButton, #LBOButton, #SubsidyButton {
  color: #0f0;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1001;
  background-color: #000000e6;
  border-radius: 3px;
  width: 98%;
  max-width: 600px;
  height: auto;
  font-family: bithigh, sans-serif;
  font-size: 1rem;
  transition: all .3s;
  position: relative;
}

#stealButton:hover {
  color: #000;
  background-color: #0f0;
  box-shadow: 0 0 10px #00ff00b3, 0 0 20px #00ff00b3;
}

#deletePortfolioButton:hover {
  color: #000;
  background-color: red;
  box-shadow: 0 0 10px #ff006b, 0 0 20px #eb00fef2;
}

#ransomButton:hover {
  color: #000;
  background-color: #fafe00f2;
  box-shadow: 0 0 10px #ff0000db, 0 0 20px #eb00fef2;
}

#createCommunityButton:hover {
  color: #fff;
  background-color: #021a6d99;
  box-shadow: 0 0 10px #00ff0079, 0 0 20px #00ff00d8;
}

#confirmSelectionMU, #sendMessageBtn {
  cursor: pointer;
  color: #3aff00;
  background: #00ff286b;
  border-top: 2px solid #88ff6f;
  border-bottom: 3px solid #6fffb5;
  border-left: 2px solid #fffe6f;
  border-right: 3px solid #ff6f6f;
  width: calc(45% - 10px);
  max-width: 90%;
  margin: 5px;
  padding: 10px 20px;
  font-family: bithigh, sans-serif;
  font-size: .7rem;
  transition: all .3s;
  display: inline-block;
  box-shadow: inset 0 0 10px #00ff00b3;
}

#cancelSelectionMU, #closePopupBtn {
  cursor: pointer;
  color: #1f0404;
  background: #ff00006b;
  border-top: 2px solid #ff00006b;
  border-bottom: 3px solid #ff6f6f;
  border-left: 2px solid #89ff6f;
  border-right: 3px solid #ff00006b;
  width: calc(45% - 10px);
  max-width: 90%;
  margin: 5px;
  padding: 10px 20px;
  font-family: bithigh, sans-serif;
  font-size: .7rem;
  transition: all .3s;
  display: inline-block;
  box-shadow: inset 0 0 10px #ff0000b3;
}

.selButMuCon {
  width: 100%;
  max-width: 100%;
}

#userSelectMU {
  appearance: none;
  color: #0f0;
  text-align: center;
  text-align-last: center;
  cursor: pointer;
  background-color: #000000d9;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: 90%;
  max-width: 90%;
  height: 40px;
  max-height: 50px;
  margin: 10px auto;
  padding: 5px 10px;
  font-family: bithigh, sans-serif;
  font-size: .9rem;
  transition: all .3s;
  box-shadow: 0 0 10px #0f06;
}

#userSelectMU:after {
  content: "";
  pointer-events: none;
  border: 6px solid #0000;
  border-top-color: #0f0;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

#userSelectMU:hover {
  background-color: #00ff001a;
  transform: scale(1.02);
  box-shadow: 0 0 15px #0f09;
}

#multiUserSelectionPopup {
  z-index: 1000;
  color: #0f0;
  text-align: center;
  background-color: #000000d9;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  max-width: 95%;
  height: 95%;
  max-height: 95%;
  padding: 20px;
  font-family: bithigh, sans-serif;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
}

#multiUserSelectionPopup h2 {
  color: #0f0;
  margin: 0 0 10px;
  font-size: 1.5rem;
}

#confirmSelectionMU:hover {
  background-color: #0f0;
}

#cancelSelectionMU {
  background-color: #ff0000b3;
}

#cancelSelectionMU:hover {
  background-color: red;
}

.holding-stocks, .shorted-stock {
  text-align: center;
  border: 1px solid #ccc;
  margin: 5px;
  padding: 10px;
}

.video-background {
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
  user-select: none;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#market-background, #stockBidBackground, #redeem-background, #redeemPopupBackground {
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
  user-select: none;
  background-image: radial-gradient(#000 1px, #0000 1px);
  background-position: center;
  background-size: 5px 5px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.CompanyName, .marketNameItem {
  margin: 70px 0 0;
}

#items-list tr {
  margin-top: 30px;
}

#stock-list tr {
  margin-top: 30px;
  margin-bottom: -50px;
}

#market-items {
  width: 100%;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 60px;
  position: relative;
}

input[type="checkbox"]:checked {
  animation: 1s infinite blink;
}

@keyframes blink {
  0% {
    background-color: red;
  }

  50% {
    color: red;
    background-color: #27ff00;
  }

  100% {
    background-color: red;
  }
}

.marketNameItem.checked {
  animation: 2s infinite blink;
}

input[type="checkbox"] {
  appearance: none;
  cursor: pointer;
  background-color: #00f;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  position: relative;
}

input[type="checkbox"]:after {
  content: "✓";
  color: #0000;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type="checkbox"]:checked:after {
  color: #0f0;
  background: none;
}

.offerBut {
  border-collapse: collapse;
  letter-spacing: 4px;
  color: #0f0;
  background: #3dd201;
  width: 100%;
  padding: 10px;
  font-family: bithigh, sans-serif;
  font-size: 1.5rem;
}

.offerBut:hover {
  color: #d202c9;
  background: #0f0;
}

#item-name, #portfolio-name {
  color: #0f0;
}

.transIm {
  text-align: center;
  color: #0f0;
  background-color: #002800f2;
  border: 2px solid #0f0;
  border-radius: 3px;
  margin: 85px 0 0;
  padding: 5px;
  overflow: hidden;
}

.animatedItNa {
  margin: 10px 0;
}

.animatedItNa p {
  color: #0f0;
  margin: 0;
}

.trans-led-board {
  margin: 5px 0;
}

#item-id, #owner-id {
  display: none;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

@keyframes textAnimation {
  0% {
    color: #0f0;
  }

  50% {
    color: #e0f;
  }

  100% {
    color: #0f0;
  }
}

#items-container {
  text-align: center;
  width: 100%;
  max-width: 100vw;
  margin: 55px auto;
}

.redeemAsk {
  color: #0f0;
  z-index: 998;
  background-color: #006400bf;
  border: 3px solid #0f0;
  max-width: 90%;
  margin: 10px auto;
  padding: 15px;
  font-family: bithigh, Arial, sans-serif;
  animation: none;
  position: relative;
}

.RedeemHold {
  width: 90%;
  height: 50px;
}

#sendRedeemOfferButton {
  letter-spacing: 2px;
  color: #0f0;
  cursor: pointer;
  z-index: 1000;
  background: #fe9900f2;
  border: 4px solid #0f0;
  width: 100%;
  margin: 0;
  padding: 15px;
  font-family: bithigh, Arial, sans-serif;
  font-size: 18px;
  display: none;
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

#sendRedeemOfferButton:hover {
  color: #000;
  background: #0f0;
}

#holdings-list input[type="radio"] {
  appearance: none;
  cursor: pointer;
  background-color: #006400bf;
  border: 2px solid #0f0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

#holdings-list input[type="radio"]:checked {
  background-color: #0f0;
  border-color: #000;
}

#holdings-list label {
  color: #0f0;
  cursor: pointer;
  font-size: 18px;
}

.holdings-redeem-list {
  color: #0f0;
  background-color: #006400bf;
  border: 4px solid #0f0;
  flex-direction: column;
  max-height: calc(100vh - 200px);
  padding-bottom: 100px;
  font-family: bithigh, Arial, sans-serif;
  display: flex;
  overflow-y: auto;
}

.header-row {
  border-top: 5px solid #0f0;
  flex-wrap: nowrap;
  display: flex;
}

.row {
  flex-direction: row;
  width: 100%;
  display: flex;
}

.cell {
  text-align: center;
  color: #0f0;
  border: 2px solid #0f0;
  padding: 10px;
  font-size: 14px;
}

.radio-cell {
  flex: .5;
}

.item-name-cell {
  flex: 3;
}

.points-cell {
  flex: 1;
}

@keyframes blinkRED {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.row.blinkRED {
  animation: 1s infinite blink;
}

#acceptOffer {
  border-collapse: collapse;
  letter-spacing: 4px;
  color: #050129;
  background: #00ff00bf;
  border: 5px solid #00ff00d8;
  border-width: 2px 5px 5px 2px;
  width: 100%;
  margin: 5px;
  padding: 10px;
  font-family: bithigh, sans-serif;
  font-size: 1.5rem;
  transition: background .3s, color .3s;
  box-shadow: 0 0 5px #27ff00cc, 0 0 10px #27ff00cc;
}

#acceptOffer:hover {
  color: #000;
  background: #0f0;
}

#declineOffer {
  border-collapse: collapse;
  letter-spacing: 4px;
  color: #0f0;
  background: #ff0000bf;
  border: 2px solid #ff0000a2;
  border-bottom-width: 3px;
  width: 100%;
  margin: 5px;
  padding: 10px;
  font-family: bithigh, sans-serif;
  font-size: 1.5rem;
  transition: background .3s, color .3s;
  box-shadow: 0 0 5px #f00c, 0 0 10px #f00c;
}

#declineOffer:hover {
  color: #000;
  background: #f66;
}

#buyOfferModal {
  color: #0f0;
  text-align: center;
  z-index: 10001;
  background-color: #000000d9;
  border: 2px solid #0f0;
  border-radius: 10px;
  align-content: center;
  width: 90%;
  max-width: 600px;
  height: 90%;
  max-height: 90%;
  padding: 20px;
  font-family: bithigh, Arial, sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px #00ff004d;
}

#buyOfferModal input[type="number"] {
  color: #0f0;
  background-color: #000;
  border: 1px solid #0f0;
  border-radius: 5px;
  width: calc(100% - 24px);
  margin-bottom: 10px;
  padding: 10px;
}

#buyOfferModal button {
  cursor: pointer;
  color: #1f00ff;
  background: #00ff28;
  margin: 5px;
  padding: 10px 20px;
  font-family: bithigh, sans-serif;
}

#offerCancelButton {
  background: #ff0000d9;
}

.tradeOffPop {
  z-index: 9999;
  color: #0f0;
  text-align: center;
  background-color: #000824c5;
  border-radius: 10px;
  align-content: center;
  width: 90%;
  max-width: 600px;
  height: auto;
  max-height: 90%;
  padding: 20px;
  font-family: bithigh, Arial, sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #0003;
}

@media (width <= 600px) {
  .tradeOffPop {
    width: 95%;
    max-width: 95%;
  }
}

.tradeOffPop button {
  letter-spacing: 4px;
  border: 2px solid #6f83ff;
  border-width: 2px 8px 9px 2px;
  width: 100%;
  margin: 5px;
  padding: 10px;
  font-family: bithigh, sans-serif;
  font-size: 1.5rem;
}

.RedeemBanner {
  text-align: center;
  z-index: 999;
  width: 100%;
  max-width: 100vw;
  height: auto;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.RedeemBanner img {
  width: 100%;
  height: auto;
  display: block;
}

.TraderBanner {
  text-align: center;
  z-index: 9999;
  width: 100%;
  max-width: 100vw;
  height: auto;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.TraderBanner img {
  width: 100%;
  height: auto;
  max-height: auto;
  display: block;
}

#TraderBanner, #marketBanner, #RedeemBanner, #stockBanner {
  object-fit: contain;
  pointer-events: none;
  width: 100%;
  max-width: 100%;
  height: auto;
}

#economic-triggers-message {
  text-align: center;
  background-color: #00000080;
  border-radius: 5px;
  margin: 15px 0;
  padding: 10px;
}

#economic-triggers-message p {
  color: #fcba03;
  margin: 0;
  font-family: bithigh, monospace;
  font-size: 18px;
}

.tradeOffPop h2 {
  margin-top: 0;
}

.tradeOffPop p {
  margin-bottom: 20px;
}

.tradeOffPop #timer {
  text-align: center;
  font-size: 1.5rem;
}

.alOffTr {
  background-color: #000824bd;
  border-top: 2px solid #ff6efd;
  border-bottom: 9px solid red;
  border-left: 2px solid #ff7f6e;
  border-right: 8px solid #6f83ff;
  border-radius: 5px;
  padding: 20px;
  font-family: bithigh, sans-serif;
  box-shadow: 0 0 10px #0003;
}

.transactionBanner {
  text-align: center;
  z-index: 999;
  width: 100%;
  max-width: 100vw;
  height: auto;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.transactionBanner img {
  width: 100%;
  height: auto;
  display: block;
}

#holdings-list2 {
  box-sizing: border-box;
  background-color: #0000644d;
  width: 100%;
  padding: 10px;
}

.transaction-row {
  margin-bottom: 5px;
  display: flex;
}

.transaction-cell {
  text-align: left;
  white-space: nowrap;
  flex: 1;
  padding: 10px;
  overflow-x: auto;
}

.transaction-cell:first-child {
  background-color: #003296b3;
}

.transaction-cell:nth-child(2) {
  background-color: #004bafb3;
}

.transaction-cell:nth-child(3) {
  background-color: #0064c8b3;
}

.transaction-cell:nth-child(4) {
  background-color: #007de1b3;
  margin-bottom: 50px;
}

.transCellTit {
  color: #aaf;
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}

.offerBut {
  color: #005196b3;
  cursor: pointer;
  background-color: #f82;
  border: none;
  padding: 5px 10px;
  font-weight: bold;
  transition: all .3s;
}

.offerBut:hover {
  background-color: #00f;
}

@media (width <= 600px) {
  .transaction-row {
    flex-direction: column;
  }

  .transaction-cell {
    color: #fff;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
    display: flex;
    overflow-x: auto;
  }

  .transCellTit {
    min-width: 90px;
    margin-bottom: 0;
    margin-right: 10px;
  }

  .transaction-cell > span:last-child {
    text-align: left;
    white-space: nowrap;
    flex: 1;
    padding-right: 10px;
    overflow-x: auto;
  }

  .transaction-cell:last-child {
    justify-content: flex-start;
  }

  .offerBut {
    width: 100%;
  }

  .transaction-row.header {
    display: none;
  }

  .transaction-cell::-webkit-scrollbar {
    height: 3px;
  }

  .transaction-cell::-webkit-scrollbar-thumb {
    background-color: #ffffff4d;
  }
}

#stock-transaction-container {
  color: #0f0;
  text-align: center;
  background-color: #000824db;
  border: 2px solid #0f0;
  border-radius: 10px;
  margin-top: 180px;
  padding: 20px;
}

#stock-transaction-container h2 {
  text-shadow: 0 0 10px #0f0;
  margin-bottom: 20px;
  font-size: 24px;
}

#stock-transaction-container p {
  margin: 10px 0;
  font-size: 18px;
}

#stock-transaction-container input[type="number"] {
  color: #0f0;
  background-color: #333333bc;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: calc(100% - 20px);
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
}

#stock-transaction-container button {
  color: #0d0d0d;
  cursor: pointer;
  background-color: #00ff00a6;
  border: 2px solid #0f0;
  border-radius: 3px;
  width: calc(100% - 20px);
  margin: 20px 0;
  padding: 10px;
  font-size: 18px;
}

#stock-transaction-container button:hover {
  color: #0f0;
  background-color: #00ff0079;
  border: 2px solid #0f0;
}

#stockBidPopup {
  z-index: 10000;
  color: #0f0;
  text-align: center;
  background-color: #000000e6;
  border: 2px solid #0f0;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  padding: 15px;
  font-family: bithigh, sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #0f0, 0 0 20px #0f0, 0 0 30px #0f0;
}

@media (width <= 600px) {
  #stockBidPopup {
    width: 95%;
    max-width: 95%;
  }
}

#stockBidPopup h2 {
  color: #0f0;
  text-align: center;
  text-shadow: 0 0 1px #0f0, 0 0 2px #0f0;
  margin-bottom: 10px;
  font-size: 1rem;
}

#stockBidPopup p {
  color: #0f0;
  margin-bottom: 20px;
  font-size: .8rem;
}

#acceptBid, #declineBid {
  cursor: pointer;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: calc(45% - 10px);
  margin: 5px;
  padding: 10px;
  font-size: .8rem;
  transition: all .3s;
  box-shadow: 0 0 5px #00ff00b3, 0 0 10px #00ff00b3;
}

#acceptBid {
  float: left;
  color: #000;
  background-color: #00ff00b3;
}

#acceptBid:hover {
  color: #000;
  background-color: #0f0;
}

#declineBid {
  float: right;
  color: #0f0;
  background-color: #ff0000b3;
  box-shadow: 0 0 5px #ff0000b3, 0 0 10px #ff0000b3;
}

#declineBid:hover {
  color: #000;
  background-color: #f66;
}

#bidTimer {
  color: #0f0;
  margin-top: 10px;
  font-size: .8rem;
}

#OF2-overlay {
  z-index: 10000;
  background-color: #000000bf;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#OF2-offerSentPopup {
  color: #0f0;
  text-align: center;
  z-index: 10001;
  background-color: #01203bb3;
  border: 2px solid #0f0;
  border-radius: 3px;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 20px;
  font-family: bithigh, Arial, sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#OF2-tradeOfferPopup {
  z-index: 9999;
  color: #0f0;
  background-color: #000000bf;
  align-content: center;
  width: 95vw;
  max-width: 100vw;
  height: 95vw;
  max-height: 95vw;
  padding: 20px;
  font-family: bithigh, Arial, sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #0003;
}

#OF2-tradeOfferPopup button {
  letter-spacing: 2px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  margin: 5px;
  padding: 10px;
  font-family: bithigh, Arial, sans-serif;
  font-size: 1.5rem;
  transition: background .3s, color .3s;
}

#OF2-acceptOffer {
  color: #050129;
  background: #00ff0080;
  border: 2px solid #00ff00f2;
  border-bottom-width: 3px;
  box-shadow: 0 0 5px #27ff00cc, 0 0 10px #27ff00cc;
}

#OF2-acceptOffer:hover {
  color: #000;
  background: #0f0;
}

#OF2-declineOffer {
  color: #0f0;
  background: #ff000080;
  border: 2px solid #ff0000f2;
  border-bottom-width: 3px;
  box-shadow: 0 0 5px #f00c, 0 0 10px #f00c;
}

#OF2-declineOffer:hover {
  color: #000;
  background: #f66;
}

#OF2-popup-container {
  color: #0f0;
  z-index: 10001;
  background-color: #000000d9;
  border: 2px solid #0f0;
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  max-width: 500px;
  padding: 20px;
  font-family: bithigh, Arial, sans-serif;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#OF2-popup-container button {
  cursor: pointer;
  border-radius: 5px;
  width: 45%;
  padding: 10px 20px;
  font-family: bithigh, sans-serif;
  transition: background .3s, color .3s;
  box-shadow: 0 0 5px #00ff0080;
}

#OF2-acceptBuyOffer {
  color: #000209f1;
  background: #0f09;
  border: 2px solid #00ff00d8;
  border-bottom-width: 3px;
}

#OF2-acceptBuyOffer:hover {
  color: #000;
  background: #0f0;
}

#OF2-declineBuyOffer {
  color: #fff600;
  background: #f009;
  border-top: 2px solid #ff0000e3;
  border-bottom: 3px solid #ff0000e3;
  border-left: 2px solid #ff0000db;
  border-right: 2px solid #ff0000e3;
}

#OF2-declineBuyOffer:hover {
  color: #000;
  background: #f66;
}

.OF2-notification {
  color: #000;
  z-index: 10001;
  text-align: center;
  text-align: left;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: 80%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  font-family: bithigh, Arial, sans-serif;
  transition: right .5s ease-out;
  position: fixed;
  bottom: 8%;
  left: 5%;
  right: -100%;
}

#BondPop-popup-container {
  color: #0f0;
  z-index: 10001;
  background-color: #000000d9;
  border: 2px solid #0f0;
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  max-width: 500px;
  padding: 20px;
  font-family: bithigh, Arial, sans-serif;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#BondPop-popup-container button {
  cursor: pointer;
  border-radius: 5px;
  width: 45%;
  padding: 10px 20px;
  font-family: bithigh, sans-serif;
  transition: background .3s, color .3s;
  box-shadow: 0 0 5px #00ff0080;
}

#BondPop-acceptOffer {
  color: #000209f1;
  background: #0f09;
  border: 2px solid #00ff00d8;
  border-bottom-width: 3px;
}

#BondPop-acceptOffer:hover {
  color: #000;
  background: #0f0;
}

#BondPop-declineOffer {
  color: #fff600;
  background: #f009;
  border-top: 2px solid #ff0000e3;
  border-bottom: 3px solid #ff0000e3;
  border-left: 2px solid #ff0000db;
  border-right: 2px solid #ff0000e3;
}

#BondPop-declineOffer:hover {
  color: #000;
  background: #f66;
}

#bond-holdings-container {
  background-color: #000064cc;
  border: 4px solid #0f0;
  border-radius: 5px;
  margin: 20px;
  padding: 15px;
  display: block;
}

#bond-holdings-container h2 {
  color: #01fbf4cc;
  text-align: center;
  margin-bottom: 20px;
}

.bond-item {
  background-color: #9610c0cd;
  border: 2px solid #31034099;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1rem;
}

.bond-item span {
  color: #0f0;
  margin: 5px 0;
  padding: 5px;
  display: block;
}

.bond-item span:nth-child(odd) {
  background-color: #6d2783cd;
}

.bond-item span:nth-child(2n) {
  background-color: #0139fb33;
}

.bond-item span strong {
  background-color: #5701fbcc;
  width: 130px;
  margin-right: 10px;
  padding: 5px;
  font-weight: bold;
  display: inline-block;
}

.bond-item span:nth-child(2n) strong {
  background-color: #31034099;
}

.bond-table {
  border-collapse: collapse;
  width: 100%;
  display: table;
}

.bond-row {
  display: table-row;
}

.bond-cell {
  vertical-align: middle;
  text-align: left;
  background-color: #6d278399;
  padding: 8px 12px;
  display: table-cell;
}

.bond-title-row .bond-cell {
  color: #fdfe00e6;
  text-align: center;
  border-radius: 5px 5px 0 0;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
}

.bond-label {
  color: #fdfe00c8;
  background-color: #551478cc;
  font-weight: bold;
}

.bond-data {
  color: #fbbb74db;
}

.bond-row:hover .bond-cell {
  background-color: #fff3;
}

.bond-cell:last-child {
  text-align: right;
}

.bond-row:last-child .bond-cell {
  border-bottom: none;
}

.bond-item:hover {
  transition: all .2s ease-in-out;
  transform: scale(1.01);
  box-shadow: 0 4px 8px #0003;
}

#zollar-container {
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
}

#zollar-holdings-list {
  background: #08073abf;
  border: 3px solid #b671f7;
  border-radius: 3px;
  flex-direction: column;
  gap: .5rem;
  width: 90%;
  padding: 15px;
  display: flex;
}

.zollar-item {
  background-color: #0003;
  border: 2px solid #00ff24b9;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.zollar-item p {
  z-index: 1;
  text-align: left;
  background-color: #00000080;
  border-radius: 5px;
  flex: 1;
  margin: 0;
  padding: 5px 10px;
  font-size: 16px;
  position: relative;
}

.zollar-item video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-bar {
  z-index: 1;
  background: linear-gradient(to top, #9aaf4cb7, #00ff24b9);
  border: 2px solid #00ff24b9;
  border-radius: 5px;
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  width: 60px;
  height: 150px;
  margin-left: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 5px #00000080;
}

.progress-fill {
  background: linear-gradient(to top, #3537ffbf, #fd00d7b7);
  border-radius: 5px 5px 0 0;
  align-self: stretch;
  width: 100%;
  min-width: 100%;
  height: 0%;
  transition: height .5s ease-in-out;
}

.progress-bar:before {
  content: "";
  pointer-events: none;
  background: repeating-linear-gradient(#0000, #0000 10px, #93ef49b6 10px, #72ff0075 12px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.progress-bar:after {
  content: "";
  border-left: 3x solid transparent;
  border-bottom: 5px solid #ff00eb;
  border-right: 3px solid #0000;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.progress-fill:hover {
  box-shadow: 0 0 10px #ffff00b3;
}

.relationship-slider {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  display: flex;
}

.relationship-slider label {
  color: #333;
  margin-bottom: 5px;
  font-weight: bold;
}

.range-slider:hover {
  background-color: #a0a0a0;
}

.relationship-slider span {
  color: #555;
  margin-left: 5px;
  font-style: italic;
}

.video-container-zollar {
  width: 100%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}

@media (width <= 600px) {
  #zollar-container, #stock-holdings-container, #qr-codes-container {
    width: 95%;
    margin: 10px auto;
  }

  .holding-item, .holding-stocks, .zollar-item, .qr-code-item {
    margin-bottom: 15px;
  }

  #zollar-container, #stock-holdings-container, #qr-codes-container {
    clear: both;
  }
}

#zollarInputs {
  margin-top: 20px;
}

#traderGaugeContainer {
  background: linear-gradient(135deg, #00ffff1a, #ffffff0d);
  border: 2px solid #00ffffb3;
  border-radius: 3px;
  width: 300px;
  height: 70px;
  margin: 15px auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 25px #00ffff80, inset 0 0 10px #00ffff80;
}

.custom-slider-container {
  margin-bottom: 15px;
}

.custom-slider-container label {
  font-weight: bold;
}

.slider-track {
  background-color: #d0d0d0;
  border-radius: 5px;
  width: 100%;
  height: 10px;
  margin: 10px 0;
  position: relative;
}

.slider-thumb {
  cursor: pointer;
  background-color: #ff0075;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  transform: translateX(-50%);
}

.range-slider {
  appearance: none;
  background-color: #d0d0d0;
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 8px;
  margin-bottom: 5px;
  transition: background-color .3s;
}

.range-slider::-webkit-slider-thumb {
  appearance: none;
  cursor: pointer;
  background-color: #ff0075;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.range-slider::-moz-range-thumb {
  cursor: pointer;
  background-color: #ff0075;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

#bond-modal {
  z-index: 1000;
  background-color: #0006;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

#bond-modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  margin: 15% auto;
  padding: 20px;
}

.bond-details {
  color: #0f0;
  background-color: #000000d9;
  border: 3px solid #ffa500cc;
  border-radius: 10px;
  width: 98%;
  max-width: 99%;
  margin: 0 auto;
  padding: 20px;
  font-family: bithigh, Arial, sans-serif;
  font-size: .7rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 15px #ffa500cc;
}

.bond-details h2 {
  color: orange;
  text-align: center;
  text-shadow: 0 0 10px orange;
  border-bottom: 2px solid #ffa500cc;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-size: .8rem;
}

.bond-info {
  margin-bottom: 20px;
  font-size: .7rem;
}

.info-row {
  border-bottom: 1px solid orange;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  display: flex;
}

.info-label {
  color: orange;
  font-size: .7rem;
  font-weight: bold;
}

.info-value {
  color: #fd00d7b7;
  font-size: .7rem;
  font-weight: bold;
}

.bid-section {
  text-align: center;
  margin-top: 20px;
}

.bid-section h3 {
  color: #ffa500e6;
  text-shadow: 0 0 10px #ffa500cc;
  margin-bottom: 15px;
  font-size: 1rem;
}

.bid-section input[type="number"] {
  color: #0f0;
  background-color: #00285099;
  border: 2px solid #0f0;
  border-radius: 5px;
  width: 80%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: .7rem;
  box-shadow: inset 0 0 5px #00ff00b3;
}

.bid-section button {
  color: #000;
  cursor: pointer;
  background-color: #ffa500cc;
  border: 2px solid #ffa500e6;
  border-radius: 2px;
  width: 90%;
  max-width: 95%;
  padding: 10px 20px;
  font-size: .7rem;
  transition: all .3s;
  box-shadow: 0 0 15px #ffa500cc;
}

.bid-section button:hover {
  background-color: orange;
  box-shadow: 0 0 20px orange;
}

.bid-section button:active {
  transform: scale(.98);
  box-shadow: 0 0 10px #ffa500b3;
}

.bondBuyCon-overlay {
  z-index: 1001;
  background-color: #000000d9;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.bondBuyCon-content {
  color: #61006690;
  text-align: center;
  background-color: #08073abf;
  border: 2px solid #ce00ff;
  border-radius: 8px;
  width: 95%;
  max-width: 98%;
  padding: 20px;
  font-family: bithigh, Arial, sans-serif;
  box-shadow: 0 0 15px #fd00d7b7;
}

.bondBuyCon-content p {
  margin-bottom: 20px;
  font-size: 1rem;
}

.bondBuyCon-content button {
  color: #000;
  cursor: pointer;
  background-color: #00ff00b3;
  border: 2px solid #0f0;
  border-radius: 5px;
  margin: 10px;
  padding: 10px 20px;
  font-family: bithigh, Arial, sans-serif;
  font-size: 1rem;
  transition: all .3s;
  display: inline-block;
  box-shadow: 0 0 10px #00ff00b3;
}

.bondBuyCon-content button:hover {
  color: #000;
  background-color: #0f0;
}

.bondBuyCon-content button:active {
  transform: scale(.98);
  box-shadow: 0 0 5px #00ff0080;
}

.bondBuyCon-cancel {
  color: #000;
  cursor: pointer;
  background-color: #ff30309d;
  border: 2px solid red;
  border-radius: 5px;
  margin: 10px;
  padding: 10px 20px;
  font-family: bithigh, Arial, sans-serif;
  font-size: 1rem;
  transition: all .3s;
  display: inline-block;
  box-shadow: 0 0 10px #ff30309d;
}

.bondBuyCon-cancel:active {
  transform: scale(.98);
  box-shadow: 0 0 5px #ff30309d;
}

.buyBondButton {
  color: #000;
  cursor: pointer;
  background-color: #ffdd00b0;
  border: 2px solid #ce00ff;
  border-radius: 5px;
  width: 80%;
  max-width: 100%;
  margin: 10px;
  padding: 10px 20px;
  font-family: bithigh, Arial, sans-serif;
  font-size: 1rem;
  transition: all .3s;
  display: inline-block;
  box-shadow: 0 0 10px #ffdd00b0;
}

.buyBondButton:hover {
  color: #000;
  background-color: #0f0;
}

.buyBondButton:active {
  transform: scale(.98);
  box-shadow: 0 0 5px #00ff0080;
}

.bidBondButton {
  color: #000;
  cursor: pointer;
  background-color: #ffdd00b0;
  border: 2px solid #ce00ff;
  border-radius: 5px;
  width: 80%;
  max-width: 100%;
  margin: 10px;
  padding: 10px 20px;
  font-family: bithigh, Arial, sans-serif;
  font-size: 1rem;
  transition: all .3s;
  display: inline-block;
  box-shadow: 0 0 10px #ffdd00b0;
}

.bidBondButton:hover {
  color: #000;
  background-color: #0f0;
}

.bidBondButton:active {
  transform: scale(.98);
  box-shadow: 0 0 5px #00ff0080;
}

@media screen and (width <= 768px) {
  .bond-details {
    max-width: 90%;
    padding: 15px;
  }

  .info-row {
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
  }

  .info-label, .info-value, .bid-section input[type="number"] {
    width: 100%;
  }
}

.close-modal {
  color: #aaa;
  float: right;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
}

.close-modal:hover, .close-modal:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.lbo-popup {
  z-index: 1000;
  color: #0f0;
  text-align: left;
  background-color: #0f0f0ff2;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  padding: 20px;
  font-family: bithigh, sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 20px #0f0, 0 0 40px #0f0;
}

.lbo-popup h2 {
  color: #0f0;
  text-shadow: 0 0 8px #0f0;
  margin-bottom: 15px;
  font-size: 24px;
}

.lbo-popup h3 {
  color: #0f0;
  text-shadow: 0 0 5px #0f0;
  margin: 10px 0;
  font-size: 18px;
}

.lbo-popup label {
  color: #0f0;
  text-shadow: 0 0 3px #0f0;
  cursor: pointer;
  background-color: #141414cc;
  border-radius: 5px;
  margin: 5px 0;
  padding: 8px 0;
  font-size: 16px;
  display: block;
}

.lbo-popup label:hover {
  background-color: #323232cc;
}

.lbo-popup input[type="checkbox"] {
  cursor: pointer;
  margin-right: 10px;
}

.lbo-buttons {
  justify-content: space-between;
  margin-top: 20px;
  display: flex;
}

.lbo-buttons .lbo-button {
  color: #0f0;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #0000;
  border: 2px solid #0f0;
  border-radius: 8px;
  padding: 10px 20px;
  font-family: bithigh, sans-serif;
  font-weight: bold;
  transition: all .3s;
  box-shadow: 0 0 5px #0f0, 0 0 15px #0f0;
}

.lbo-buttons .lbo-button:hover {
  color: #000;
  background-color: #0f0;
  box-shadow: 0 0 20px #0f0, 0 0 30px #0f0;
}

.close-button-LBO {
  color: #0f0;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #0000;
  border: 2px solid #0f0;
  border-radius: 5px;
  padding: 8px 15px;
  font-family: bithigh, sans-serif;
  font-weight: bold;
  transition: background-color .3s, color .3s;
}

.close-button-LBO:hover {
  color: #000;
  background-color: #0f0;
  box-shadow: 0 0 10px #0f0, 0 0 20px #0f0;
}

.subsidy-popup {
  z-index: 1000;
  color: #0f0;
  background-color: #000000d9;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  font-family: bithigh, sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-button-subsidy {
  cursor: pointer;
  color: #000;
  background-color: #0f0;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 20px;
}

#SubsidyButton {
  cursor: pointer;
  color: #d1c;
  background-color: #002fffab;
  border: none;
  border-radius: 5px;
  padding: 2px;
  font-size: 16px;
}

#SubsidyButton:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px #ffd700e6;
}

.container-flex {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (width <= 600px) {
  .container-flex > div {
    width: 100%;
    margin: 40px 0 0;
    overflow: hidden;
  }
}

.bond-row td[data-title="ZBond Name"], .bond-row td[data-title="ZBond Buy"] {
  text-align: center;
  color: #fd0;
  background-color: #44003ab7;
  border-bottom: 1px solid #80008080;
  width: 100%;
  margin: 0;
  padding: 5px;
  font-size: 1.5rem;
  position: relative;
}

.bond-row td[data-title="ZBond Owner"], .bond-row td[data-title="ZBond Maturity"] {
  float: left;
  box-sizing: border-box;
  text-align: center;
  color: #fd0;
  background-color: #80008033;
  border-bottom: 1px solid #8000804d;
  width: 49%;
  margin: 0;
  padding: 5px;
  font-size: 1rem;
}

.bond-row td[data-title="ZBond Price"], .bond-row td[data-title="ZBond Interest Rate"] {
  float: right;
  box-sizing: border-box;
  text-align: center;
  color: #fd0;
  background-color: #80008033;
  border-bottom: 1px solid #8000804d;
  width: 49%;
  margin: 0;
  padding: 5px;
  font-size: 1rem;
}

.bond-row td[data-title="ZBond Buy"] button {
  color: #fd0;
  cursor: pointer;
  z-index: 2;
  background-color: #9300ffb3;
  border: 1px solid #ffdd00b0;
  border-radius: 5px;
  width: 90%;
  margin: 0 auto;
  padding: 8px 15px;
  font-family: bithigh, sans-serif;
  font-size: 1rem;
  transition: background-color .3s;
  position: relative;
}

.bond-row td[data-title="ZBond Buy"] button:hover {
  background-color: #9300ff;
  transform: translateZ(0);
}

.bond-row {
  color: #fd0;
  border-bottom: 1px solid #8000804d;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  height: auto;
  margin: 5px 0;
  padding: 5px;
  display: flex;
}

@media screen and (width <= 768px) {
  .bond-row td {
    float: none;
    width: 100%;
  }
}

@media screen and (width <= 2600px) {
  .bond-row td:before {
    content: attr(data-title);
    color: #fd0;
    text-align: center;
    z-index: 1;
    background-color: #1f054a80;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px;
    font-size: .9rem;
    font-weight: 700;
    display: block;
    position: relative;
  }
}

.modal-info {
  color: #0f0;
  text-align: left;
  background-color: #00ff001a;
  border-left: 3px solid #0f0;
  margin-bottom: 20px;
  padding: 10px;
  font-size: .9rem;
}

.input-group label {
  color: #0f0;
  margin-bottom: 5px;
  font-size: .9rem;
  display: block;
}

.input-group small {
  color: #00ff00b3;
  margin-top: 5px;
  font-size: .8rem;
  display: block;
}

.primary-btn, .secondary-btn {
  cursor: pointer;
  letter-spacing: 2px;
  border: 2px solid;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0;
  padding: 10px 20px;
  font-family: bithigh, Arial, sans-serif;
  font-size: 1rem;
  transition: all .3s;
}

.primary-btn {
  color: #0f0;
  background-color: #0f03;
  border-color: #0f0;
  box-shadow: 0 0 10px #00ff004d;
}

.primary-btn:hover {
  color: #000;
  background-color: #0f0;
  box-shadow: 0 0 20px #00ff0080;
}

.secondary-btn {
  color: #0f0;
  background-color: #0000;
  border-color: #0f0;
}

.secondary-btn:hover {
  background-color: #00ff001a;
  box-shadow: 0 0 15px #00ff004d;
}

#closeModalButton {
  background-color: #ff0000b3;
}

.warning {
  color: red;
  text-align: center;
  background-color: #ff00001a;
  border-radius: 5px;
  margin: 15px 0;
  padding: 10px;
  font-size: 1rem;
}

.success {
  background: #00ff001a;
  border: 1px solid #00ff004d;
  border-radius: 4px;
  margin-top: 1rem;
  padding: 1rem;
}

.credentials-box {
  background-color: #0000004d;
  border: 1px solid #0f0;
  border-radius: 5px;
  margin: 20px 0;
  padding: 15px;
}

.credential-item {
  background-color: #00ff001a;
  border-radius: 3px;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  display: flex;
}

.credential-item label {
  color: #0f0;
  margin-right: 10px;
  font-size: .9rem;
}

.credential-item span {
  color: #0f0;
  background-color: #0000004d;
  border-radius: 3px;
  flex-grow: 1;
  margin: 0 10px;
  padding: 5px 10px;
  font-family: monospace;
}

.copy-btn {
  color: #0f0;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #0f0;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: .8rem;
  transition: all .3s;
}

.copy-btn:hover {
  color: #000;
  background-color: #0f0;
}

#qrCodeContainer {
  background-color: #fff;
  border-radius: 5px;
  width: fit-content;
  margin: 20px auto;
  padding: 10px;
}

#qrCodeContainer canvas {
  margin: 0 auto;
  display: block;
}

.modal {
  animation: .3s ease-out fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (width <= 768px) {
  .modal-content {
    width: 95%;
    margin: 10% auto;
    padding: 15px;
  }

  .credential-item {
    flex-direction: column;
    align-items: stretch;
  }

  .credential-item span {
    margin: 5px 0;
  }

  .copy-btn {
    margin-top: 5px;
  }
}

#firstLoginModal {
  z-index: 1000;
  background-color: #000000e6;
  width: 100%;
  height: 100%;
  font-family: bithigh;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

#firstLoginModal .modal-content {
  color: #0f0;
  background-color: #002800f2;
  border: 2px solid #0f0;
  width: 90%;
  max-width: 600px;
  height: auto;
  max-height: 95vh;
  margin: 5% auto;
  padding: 15px;
  position: relative;
  overflow-y: auto;
  box-shadow: 0 0 20px #00ff004d;
}

#firstLoginModal h2 {
  color: #0f0;
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.6rem;
}

.compSerCla {
  text-align: center;
  padding: 15px;
  font-size: 1.4rem;
}

.search-label {
  color: #fdfe00ee;
  font-size: 1rem;
  font-weight: bold;
}

.search-helper {
  color: #3dd202ba;
  margin-bottom: 1em;
  font-size: .85rem;
}

#firstLoginModal .input-group {
  margin-bottom: 10px;
}

#firstLoginModal input, #firstLoginModal textarea {
  color: #0f0;
  background-color: #000000b3;
  border: 1px solid #0f0;
  width: 100%;
  padding: 8px;
  font-family: bithigh;
  font-size: 1rem;
}

#companyDescription {
  color: #0f0;
  background-color: #000c;
  border: 1px solid #0f0;
  height: 60px;
  margin: 10px 0;
}

#firstLoginModal #completeProfileBtn {
  color: #0f0;
  cursor: pointer;
  background-color: #00ff004d;
  border: 2px solid #0f0;
  width: 100%;
  margin-top: 15px;
  padding: 12px;
  font-family: bithigh;
  font-size: 1rem;
  transition: all .3s;
}

#firstLoginModal #completeProfileBtn:hover {
  background-color: #00ff0080;
  box-shadow: 0 0 10px #00ff0080;
}

#firstLoginModal .search-results {
  background-color: #000c;
  border: 1px solid #0f0;
  max-height: 250px;
  margin-top: 5px;
  padding: 5px;
  overflow-y: auto;
}

.value-input {
  color: #fff;
  background: #0000004d;
  border: 2px solid #444;
  border-radius: 6px;
  width: 100%;
  padding: 10px;
  font-size: .9rem;
  transition: all .3s;
}

.company-result {
  background: #141414f2;
  border: 1px solid #444;
  border-radius: 6px;
  margin: 8px 0;
  padding: 12px;
  font-size: .9rem;
  transition: all .3s;
}

.company-result:hover {
  background: #282828f2;
  transform: translateY(-2px);
}

.company-result h4 {
  color: #0f1;
  margin-bottom: 5px;
  font-size: 1rem;
}

.company-result p {
  color: #8f8;
  margin: 0;
  font-size: .85rem;
}

@media (width <= 768px) {
  #firstLoginModal .modal-content {
    width: 95%;
    height: auto;
    max-height: 90vh;
    margin: 8% auto;
  }

  #firstLoginModal h2 {
    margin-bottom: 10px;
    font-size: 1.3rem;
  }

  .compSerCla {
    padding: 12px;
    font-size: 1.2rem;
  }

  #firstLoginModal input, #firstLoginModal textarea {
    font-size: .95rem;
  }

  #firstLoginModal #completeProfileBtn {
    padding: 10px;
    font-size: .95rem;
  }

  .company-result {
    padding: 10px;
    font-size: .85rem;
  }

  .company-result h4 {
    font-size: .9rem;
  }

  .company-result p {
    font-size: .8rem;
  }

  .search-results {
    max-height: 200px;
  }

  .company-selection {
    margin-top: 15px;
    padding: 10px;
    position: relative;
  }
}

@media (width <= 480px) {
  #firstLoginModal .modal-content {
    width: 95%;
    margin: 4% auto;
    padding: 10px;
  }

  #firstLoginModal h2 {
    margin-bottom: 8px;
    font-size: 1.1rem;
  }

  .compSerCla {
    padding: 10px;
    font-size: 1rem;
  }

  #firstLoginModal input, #firstLoginModal textarea {
    font-size: .9rem;
  }

  #firstLoginModal #completeProfileBtn {
    padding: 8px;
    font-size: .9rem;
  }

  .company-result {
    padding: 8px;
    font-size: .8rem;
  }

  .company-result h4 {
    font-size: .85rem;
  }

  .company-result p {
    font-size: .75rem;
  }

  .search-results {
    max-height: 150px;
  }

  .company-selection {
    margin-top: 10px;
    padding: 8px;
  }
}

.titComSel1 {
  text-align: left;
  padding: 5px;
  font-size: .8rem;
}

.conversation-step {
  align-content: flex-start;
  padding: 5px;
  font-family: bithigh, sans-serif;
  font-size: .8rem;
}

.conversation-step p {
  color: #fee000e3;
}

#searchButton {
  cursor: pointer;
  background: #fdfe00a5;
  border: 1px solid #ff8700c4;
  border-radius: 6px;
  height: 34px;
  font-size: 14px;
}
/*# sourceMappingURL=bondTransaction.c81c7479.css.map */
