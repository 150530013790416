/* Ensure font loading */
@font-face {
  font-family: 'bithigh';
  src: url('./bithigh.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'bithigh';
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: #000000;
  font-family: bithigh, sans-serif;
  letter-spacing: 4px;
  overflow-x: hidden;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spinner arrows in number input fields for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

input[type="text"],
input[type="password"] {
  flex: 1;
  padding: 10px;
  margin-bottom: 10px;
  background-color: rgba(0, 40, 80, 0.6);
  border: 1px solid #00ff00;
  color: #00ff00;
  border-radius: 5px;
  box-shadow: inset 0 0 10px rgba(0, 255, 0, 0.5);
}

.footer {
  background-color: transparent;
  color: rgba(0, 255, 0, 0.739);
  text-align: center;
  padding: 10px;
  width: 100%;
  height: 25px;
  position: relative; /* Change from fixed to relative */
  margin: 0 auto;
  font-size: 0.8rem;
  position: absolute;
  bottom: 5px;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 255, 0, 0.7);
  color: #000000;
  border: 2px solid #00ff00;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.7);
}

button[type="submit"]:hover {
  background-color: #00ff00;
  color: #000000;
}


p{
  color: #00ff00;
}
h2{
  color: #00ff00;
  text-align: center;
  
}
h3{
  color: #00ff00;
  text-align: center;
  font-size: 3rem;
  padding: 40px 10px 10px 10px;

  
}
h4{
  font-size: 1.5rem;
  color: #00ff00;
  text-align: center;
  background-color: rgba(253, 254, 0, 0.2);
  padding: 10px 0 12px 0;
}
video {
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


#scan-alert-modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

#scan-alert-modal .modal-content {
  background-color: rgba(0, 100, 0, 0.75);
  margin: 15% auto;
  padding: 20px;
  border: 2px solid #00ff00;
  width: 80%;
  max-width: 400px;
  color: #00ff00;
  text-align: center;
  border-radius: 5px;
}

#scanButton {
  background-color: rgba(114, 255, 0, 0.8);
  color: rgb(0, 11, 53);
}

#fulfillButton {
  background-color: rgba(255, 113, 0, 0.8);
  color: rgb(0, 11, 53);
}

/*  styling for all Elements trader */
#logOut {
  padding: 10px;
  margin: 10px 0 50px 0px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  font-family: 'bithigh', Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: rgba(255, 0, 0, 0.588);
  color: #00ff00; 
  border: 2px solid #ff0000; 
  border-radius: 1px; 
  cursor: pointer;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.588);
  transition: all 0.3s ease;
  z-index: 999999;
  position: relative;
  float: left;
}

#logOut:hover {
  background: #00ff00; /* Neon green background on hover */
  color: #000000; /* Black text on hover */
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.7); /* Enhanced glow effect */
}

#logOut:active {
  transform: scale(0.98); 
  box-shadow: 0 0 2px rgba(0, 255, 0, 0.5); 
}


.trader-name{
  margin-top: 5%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin-top: 5%;
  text-align: center;
}
.traderTitNam{
  padding: 3px;
  margin-top: 60px;
  color: #00ff00;
  text-align: center;
}


#holdings-container {
  display: block; 
  margin: 20px; 
}

.holding-item {
  contain: content;
  border: 4px solid #00ff00; 
  padding: 15px;
  margin-bottom: 20px; 
  font-size: 16px;
  background-color: rgba(0, 100, 0, 0.75);
  border-radius: 5px; 
}

.holding-item p {
  margin: 10px 0; 
  padding: 5px;
}

/* Apply alternating background colors to paragraphs */
.holding-item:nth-child(odd) p {
  background-color: rgba(0, 255, 0, 0.1); 
}

.holding-item:nth-child(even) p {
  background-color: rgba(0, 255, 0, 0.2);
}

.holding-item strong {
  width: 130px; 
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
  background-color: rgba(0, 255, 0, 0.1);
  padding: 5px;
}


/* Apply darker tone background to every second label */
.holding-item:nth-child(even) strong {
  background-color: rgba(0, 255, 0, 0.2);
}

#stock-holdings-container {
  display: block;
  margin: 0;
  border: 3px solid rgba(36, 65, 192, 0.895);
  padding: 10px;
  background-color: rgba(1, 124, 251, 0.8);
  border-radius: 5px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
}

#stock-holdings-container h2 {
  color: rgba(1, 251, 244, 0.8);
  text-align: center;
  margin: 0 0 10px 0;
  font-size: 1.2rem;
}

.holding-stocks {
  border: 1px solid rgba(1, 251, 244, 0.8);
  padding: 8px;
  margin-bottom: 8px;
  background-color: rgba(1, 124, 251, 0.8);
  border-radius: 4px;
  position: relative;
}

.stock-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(1, 251, 244, 0.5);
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.stock-name {
  color: #00ff00;
  font-weight: bold;
  font-size: 0.95rem;
  width: 100%;
  content-align: center;
}

.stock-symbol {
  color: rgba(1, 251, 244, 0.8);
  background-color: rgba(8, 27, 111, 0.895);
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.8rem;
}

.stock-details {
  display: flex;
  justify-content: space-between;
}

/* Short position details styling */
.shorts-container {
  margin-top: 10px;
  border-top: 1px dashed #ccc;
  padding-top: 8px;
}

.short-position-detail {
  background-color: rgba(220, 53, 69, 0.1);
  border-left: 3px solid #dc3545;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
}

.short-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.short-label {
  background-color: #dc3545;
  color: rgba(61, 210, 2, 0.729);
  font-size: 11px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 3px;
  margin-right: 8px;
}

.shorter-name {
  font-weight: bold;
  font-size: 14px;
}

.short-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.short-quantity, .short-value {
  font-size: 13px;
  color: rgba(254, 224, 0, 0.892);
}

.short-reason {
  font-size: 13px;
  font-style: italic;
  color: rgba(253, 254, 0, 0.932);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 5px;
  margin-top: 5px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 2px;
}

.detail-label {
  color: rgba(1, 251, 244, 0.8);
  font-size: 0.7rem;
  background-color: rgba(1, 57, 251, 0.4);
  width: 100%;
  text-align: center;
  padding: 2px 0;
  border-radius: 2px 2px 0 0;
}

.detail-value {
  color: #00ff00;
  font-size: 0.85rem;
  background-color: rgba(1, 57, 251, 0.2);
  width: 100%;
  text-align: center;
  padding: 2px 0;
  border-radius: 0 0 2px 2px;
}

.holding-stocks span {
  display: block;
  margin: 5px 0;
  padding: 5px;
  color: #00ff00;
}

.holding-stocks span:nth-child(odd) {
  background-color: rgba(1, 57, 251, 0.4);
}

.holding-stocks span:nth-child(even) {
  background-color: rgba(1, 57, 251, 0.2);
}

.holding-stocks span strong {
  width: 130px;
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
  background-color: rgba(1, 57, 251, 0.8);
  padding: 5px;
}

.holding-stocks span:nth-child(even) strong {
  background-color: rgba(1, 124, 251, 0.8);
}

.trader-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trader-popup-content {
  background: rgba(0, 5, 29, 0.792);
  padding: 20px;
  border-radius: 5px;
  max-width: 600px;
  width: 90%;
  position: relative;
  border: 2px solid rgba(61, 210, 2, 0.729)
}
#recipientList {
  color: rgba(0, 255, 0, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recipient {
  display: flex;
  align-items: center;
  margin-bottom: 5px; 
  width: 100%; 
  max-width: 300px; 
}

.recipient input[type="checkbox"] {
  margin-right: 10px; /* Space between checkbox and label */
}

#messagePopup {
  width: 90%;
  max-width: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 2, 9, 0.921);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  z-index: 1000;
  font-family: 'bithigh', sans-serif; 
  text-align: center;
}

#messagePopup select, #messagePopup textarea {
  width: 90%;
  max-width: 90%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 2px solid #00ff00; 
  color: #00ff00; 
  border-radius: 5px;
  box-shadow: inset 0 0 10px rgba(0, 255, 0, 0.7); 
}

#messagePopup h2, #messagePopup p {
  margin: 10px 0;
  color: #00ff00; 
}

#messagePopup button {
  width: calc(50% - 10px);
  padding: 10px;
  font-size: 16px;
  border: 2px solid #00ff00; /* Neon green border */
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.7); /* Outer glow effect */
  transition: all 0.3s ease;
}

#messagePopup button:hover {
  background-color: #00ff00; /* Neon green background */
  color: #000000; /* Black text */
}

#messageButton {
  width: 98%;
  max-width: 600px; /* Adjust as needed */
  height: auto;
  /*border: 2px solid #00ddff;*/
  background-color: rgba(0, 0, 0, 0.9);
  color: #00ff00;
  font-family: bithigh, sans-serif;
  letter-spacing: 2px;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1000;
  margin: 10px 0 10px 0;
}


#messageButton:hover {
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.9);
  transform: scale(1.05);
}
.received-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
}
#acceptCommunity{
  padding: 10px;
    width: 48%;
    border: 2px solid #00ff00; /* Neon green border */
    margin: 10px 0;
    font-family: 'bithigh', Arial, sans-serif;
    font-size: 14px;
    background-color: rgba(0, 255, 0, 0.2); /* Semi-transparent green background */
    color: rgba(0, 255, 36, 0.724); /* Neon green text */
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    border-radius: 5px; /* Rounded corners */
    transition: all 0.3s ease;
    box-shadow: 0 0 5px rgba(0, 255, 0, 0.5); /* Subtle glow effect */
  
}

#declineCommunity, #cancelSelectionSubsidy, #cancelSelectionLBO{
  padding: 10px;
    width: 48%;
    border: 2px solid #ff0000;
    margin: 10px 0;
    font-family: 'bithigh', Arial, sans-serif;
    font-size: 12px;
    background-color: rgba(255, 0, 0, 0.2);
    color: rgba(255, 0, 0, 0.6); 
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.634);
}
/* Steal Modal Overlay */
.modal-steal {
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

/* Modal Content */
.modal-steal-content {
  background-color: #111; /* Deep black with no gloss */
  margin: 10% auto;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  border-radius: 10px;
  color: #00ff00;
  font-family: 'bithigh', sans-serif;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.8), 0 0 50px rgba(0, 255, 0, 0.4);
}

/* Close Button */
#closeMoodPopup{
  font-size: 1.5rem;
  color: #00ff00;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
  background-color: rgba(255, 0, 0, 0.7);
}
.modal-steal-close {
  float: right;
  font-size: 1.5rem;
  color: #00ff00;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

.modal-steal-close:hover {
  color: #3aff3a;
  transform: scale(1.2);
}

.modal-steal-item-list {
  height: calc(100% - 40px); /* Adjust based on modal padding or other factors */
  max-height: 600px; /* Add a sensible maximum height */
  width: 100%; /* Full width of the parent container */
  overflow-y: auto; /* Enable scrolling for vertical overflow */
  padding: 10px;
  margin: 10px 0;
  border: 1px solid rgba(0, 255, 0, 0.5);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  scrollbar-width: thin; /* For Firefox: thin scrollbar */
  scrollbar-color: rgba(0, 255, 0, 0.5) rgba(0, 0, 0, 0.5); /* For Firefox: colors */
}

.modal-steal-item-list::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.modal-steal-item-list::-webkit-scrollbar-thumb {
  background: rgba(0, 255, 0, 0.5); /* Scrollbar color */
  border-radius: 4px;
}

.modal-steal-item-list::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.5); /* Scrollbar track color */
}


.modal-steal-item {
  padding: 10px;
  margin: 5px 0;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid #00ff00;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.modal-steal-item:hover {
  will-change: transform;
  background-color: rgba(0, 255, 0, 0.2);
  transform: translateX(5px);
}

.give-away-button, #confirmSelectionSubsidy, #confirmSelectionLBO {
  padding: 10px;
  width: 100%;
  border: 2px solid #00ff00; /* Neon green border */
  margin: 10px 0;
  font-family: 'bithigh', Arial, sans-serif;
  font-size: 12px;
  background-color: rgba(0, 255, 0, 0.2); /* Semi-transparent green background */
  color: rgba(0, 255, 36, 0.6); /* Neon green text */
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border-radius: 5px; /* Rounded corners */
  transition: all 0.3s ease;
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.5); /* Subtle glow effect */
}

.give-away-button:hover {
  background-color: #00ff00; /* Solid green background on hover */
  color: #000000; /* Black text on hover */
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.7); /* Enhanced glow effect on hover */
}

.give-away-button:active {
  transform: scale(0.98); /* Slight scale down when clicked */
  box-shadow: 0 0 2px rgba(0, 255, 0, 0.5); /* Reduced glow when clicked */
}
#confirmGiveAwayPopup {
  width: 98%;
  max-width: 98%;
  height: auto; /* Adjusts to fit content height */
  max-width: 400px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.85);
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  color: #00ff00;
  font-family: 'bithigh', sans-serif;

  /* Flexbox for centering */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center;
}


#confirmGiveAwayPopup h2 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #00ff00;
  font-size: 1.5rem;
}

#confirmGiveAwayPopup p {
  margin-bottom: 20px;
  color: #00ff00;
  font-size: 1rem;
}

#confirmGiveAwayPopup .popup-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

#confirmGiveAwayPopup button {
  flex: 1;
  padding: 10px 20px;
  font-size: 1rem;
  border: 2px solid #00ff00;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

#confirmGiveAwayPopup button#confirmGiveAway {
  background-color: rgba(0, 255, 0, 0.7);
  color: #000000;
}

#confirmGiveAwayPopup button#confirmGiveAway:hover {
  background-color: #00ff00;
  color: #000000;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.7);
}

#confirmGiveAwayPopup button#cancelGiveAway {
  background-color: rgba(255, 0, 0, 0.7);
  color: #000000;
}

#confirmGiveAwayPopup button#cancelGiveAway:hover {
  background-color: #ff0000;
  color: #000000;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
}

.hiddenST {
  display: none;
}
.hiddenMU {
  display: none;
}
.hiddenRS {
  display: none;
}

.hiddenCM {
  display: none;
}

.hiddenSubsidy {
  display: none;
}
.hiddenLBO {
  display: none;
}

#userSelectSubsidy, #userSelectLBO{
width:95%; max-width:95%; height:5%; max-height:5%; padding: 10px; margin-bottom: 10px; border-radius: 5px; border: 2px solid #00ff00; background-color: #000; color: #00ff00;
}
/* For mobile responsiveness */
@media screen and (max-width: 768px) {
  .give-away-button {
  font-size: 12px;
  padding: 8px;
  }
}
#points-container {
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 10px;
  padding: 2px 0;
  border-top: 1px solid rgba(255, 135, 0, 0.858);
  border-bottom: 2px solid rgba(255, 135, 0, 0.858);
  background-color: rgba(0, 36, 0, 0.75);
}

#total-points {
  color: #00ff00;
  font-weight: bold;
  font-size: 24px;
  padding: 1px 0;
}
.zolPop {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  line-height: 1.5rem;
  margin: 0 auto;
  font-size: .7rem;
}
.led-board {
  font-size: 0;
  line-height: 0;
  margin: 0 auto;
}

.led {
  display: inline-block;
}

.total-pointsB {
  background-color: rgba(28, 210, 2, .4);
  padding: 10px;
  font-size: 1.8rem;
  border-radius: 4px;
  margin: 5px 0 5px !important;
}

.item-points {
  font-size: 14px;
  color: #00ff00;
  display: block;
  margin: 5px 0;
  padding-bottom: 5px;
}


#qr-codes-container {
  margin-top: 30px;
  padding: 10px 0;
  border-top: 2px solid #00ff00; /* Dashed border for a distinct retro look */
  text-align: center; /* Ensures that the children are centered */
}

.qr-code-item {
  border: 2px solid #00ff00;
  padding: 20px;
  margin-bottom: 15px;
  display: inline-block; /* Keep inline-block for proper alignment */
  width: 250px; /* Fixed width for uniformity */
  background-color: rgba(0, 100, 0, 0.75); /* Darker green transparent background with 0.75 opacity */
  text-align: center; /* Centering text and QR code within the item */
  color: #00ff00; /* Green text for the retro effect */
  vertical-align: top; /* Align tops of inline-block elements */
  margin-left: auto; /* Auto margins for horizontal centering */
  margin-right: auto;
}

.qr-code-item p {
  margin: 10px 0; /* Add more space around text */
  font-size: 14px; /* Adjust font size for readability */
}

.qr-code-item img {
  display: block; /* Ensures the image is centered within its container */
  margin: 10px auto; /* Auto margins effectively center the image horizontally */
  width: 150px; /* Set a specific width for the QR code images */
  padding: 5px; /* Padding around the image */
  background-color: rgba(0, 100, 0, 0.75); /* Darker green transparent background with 0.75 opacity */
  border: 1px solid #00ff00; /* Green border around the QR code image */
}


#qr-codes-container {
  margin-top: 30px;
  padding: 10px 0;
  border-top: 2px dashed #00ff00;
  text-align: center;
}

.qr-code-item {
  border: 2px solid #00ff00;
  padding: 20px;
  margin-bottom: 15px;
  display: inline-block;
  width: 250px;
  background-color: rgba(0, 100, 0, 0.75);
  text-align: center;
  color: #00ff00;
  vertical-align: top;
  margin-left: auto;
  margin-right: auto;
}

.qr-code-item p {
  margin: 10px 0;
  font-size: 14px;
}

.qr-code-item img {
  display: block;
  margin: 10px auto;
  width: 150px;
  padding: 5px;
  background-color: rgba(0, 100, 0, 0.75);
  border: 1px solid #00ff00;
  cursor: pointer;
}

/* Modal styles */
.modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: rgba(0, 100, 0, 0.75);
  margin: 15% auto;
  padding: 20px;
  border: 2px solid #00ff00;
  width: 80%;
  max-width: 400px;
  color: #00ff00;
  font-family: 'bithigh', Arial, sans-serif;
  border-radius: 5px;
}

.modal-content h2 {
  margin-top: 0;
  color: #00ff00;
}
#poolNameInput, #userSearchInput {
  width: 90%;
  max-width: 90%;
  height: 50px;
  max-height: 50px;
  line-height: 50px; /* Match the height to center text vertically */
  box-sizing: border-box;
}

.modal-content input,
.modal-content select {
  display: block;
  margin-bottom: 10px;
  padding: 8px;
  font-size: 14px;
  background-color: rgba(0, 100, 0, 0.5);
  border: 1px solid #00ff00;
  color: #00ff00;
  border-radius: 4px;
}

.modal-content button {
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  background-color: rgba(0, 255, 0, 0.7);
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}

.modal-content button:hover {
  background-color: #00ff00;
}
.modal-buttons {
  margin-top: 20px;
}

.modal-btn {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


@-webkit-keyframes zoom {
  from {-webkit-transform: scale(0)}
  to {-webkit-transform: scale(1)}
}

@keyframes zoom {
  from {transform: scale(0)}
  to {transform: scale(1)}
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.info-icon {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 3px;
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  background-color: rgba(0, 2, 9, 0.921);
  margin-left: 10px;
  position: relative;
  padding: 5px;
  color: rgba(0, 255, 0, 0.849);
}

.input-group input {
  flex: 1;
}

.info-icon:hover::after {
  content: attr(title);
  position: absolute;
  top: -5px;
  right: 25px;
  background: rgba(0, 8, 36, 0.83);
  color: #fff;
  padding: 25px;
  border-radius: 5px;
  white-space: normal; 
  z-index: 1000;
  font-size: 12px;
  width: 50vw; 
  max-width: 50vw; 
  text-align: left;
}

.modal, .info-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal-content, .info-modal-content {
  background-color: rgba(2, 26, 109, 0.6);
  margin: 150px auto;
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
  max-width: 500px;
  text-align: left;
}

.info-modal-content {
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.info-modal-content button {
  margin-top: 20px;
}

.zeconComp{
  text-align: left;
}
#qr-code-modal-details {
  color: #00ff00;
  text-align: center;
  margin-top: 20px;
}

#qr-code-modal-details p {
  font-size: 18px;
  margin: 10px 0; /* Space out the paragraphs */
}


@keyframes blinkRede {
  0% { background-color: #f5fc00; color: #c200ff; } /* Initial colors */
  50% { background-color: #00ffed; color: #d27802; } /* Complementary colors */
  100% { background-color: #f5fc00; color: #c200ff; } /* Back to initial colors */
}

#items-list{
  margin-bottom: 20px;
}
.marketBorderHr{
  height: 3px;
  color: #2f0;
}

#items-list tr:last-child {
  border-bottom: none; /* No border for the last row */
}
/* Strongly target the headers to avoid conflicts and ensure they are hidden */
#items-table thead tr th:nth-child(8),
#items-table thead tr th:nth-child(9) {
  display: none;
}



.marketBanner {
  max-width: 100vw;
  width: 100%;
  margin: 0 auto; /* Adjust margin */
  text-align: center;
  position: fixed; /* Make the banner fixed */
  top: 0;
  left: 0;
  right: 0;
  z-index: 999; /* Ensure the banner stays above the background */
  height: auto;
}

.marketBanner img {
  width: 100%;
  height: auto;
  display: block;
}



:root {
  --glow-color: #f8c3d9;
  --glow-spread-color: rgba(35, 0, 255, 0.508);
  --enhanced-glow-color: #6effb5;
  --btn-color: rgba(185, 2, 210, 0.8);
  --hover-bg-color: #ff7900;
  --hover-text-color: #6effb5;
}

#goToMarketBtn {

  padding: 10px;
  max-width: 100vw;
  width: 100%;
  box-sizing: border-box;
  font-family: bithigh, sans-serif;
  letter-spacing: 4px;
  font-size: 1rem;
  background-color: var(--btn-color);
  color: var(--glow-color);
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  bottom: 0;
  z-index: 1002; /* Ensure the button stays above other elements */
  border: 2px solid var(--glow-color);
  border-radius: 3px;
  outline: none;
  box-shadow: 0 0 1em .25em var(--glow-color),
        0 0 4em 1em var(--glow-spread-color),
        inset 0 0 .75em .25em var(--glow-color);
  text-shadow: 0 0 .5em var(--glow-color);
  transition: all 0.3s;
}

#goToMarketBtn::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 120%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: .7;
  transform: perspective(1.5em) rotateX(35deg) scale(1, .6);
}

#goToMarketBtn:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
  box-shadow: 0 0 1em .25em var(--glow-color),
        0 0 4em 2em var(--glow-spread-color),
        inset 0 0 .75em .25em var(--glow-color);
}

#goToMarketBtn:active {
  box-shadow: 0 0 0.6em .25em var(--glow-color),
        0 0 2.5em 2em var(--glow-spread-color),
        inset 0 0 .5em .25em var(--glow-color);
}
#goToDarkPoolMarketBtn {

  padding: 10px;
  max-width: 100vw;
  width: 100%;
  box-sizing: border-box;
  font-family: bithigh, sans-serif;
  letter-spacing: 4px;
  font-size: 1rem;
  background-color: var(--btn-color);
  color: var(--glow-color);
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  bottom: 0;
  z-index: 999; /* Ensure the button stays above other elements */
  border: 2px solid var(--glow-color);
  border-radius: 3px;
  outline: none;
  box-shadow: 0 0 1em .25em var(--glow-color),
        0 0 4em 1em var(--glow-spread-color),
        inset 0 0 .75em .25em var(--glow-color);
  text-shadow: 0 0 .5em var(--glow-color);
  transition: all 0.3s;
}
/* Main container for community interface */
#communityInterface {
  position: fixed;
  bottom: 60px;
  left: 50%; /* Centers the element horizontally */
  transform: translateX(-50%); /* Shifts it back by 50% of its own width for centering */
  width: 99%;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  color: #00ff00;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.7), 0 0 20px rgba(0, 255, 0, 0.7);
  text-align: center;
  font-size: 0.7rem;
  font-family: 'bithigh', sans-serif;
  z-index: 1000;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px; /* Space between elements */
}


/* Header styling */
#communityInterface h2 {
  font-size: 0.8rem;
  margin: 5px 0;
  color: #00ff00;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.8);
}

/* Timer styling inside #communityInterface */
#communityInterface .community-timer {
  width: 100%;
  padding: 4px;
  font-size: 0.9rem;
  color: #00ff00;
  text-shadow: 0 0 5px #00ff00;
  background-color: rgba(0, 0, 0, 0.465);
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(0, 255, 0, 0.5);
  text-align: center;
}

/* Leave Button Styling */
#leaveCommunityBtn {
  width: 100%;
  padding: 6px;
  background-color: rgba(255, 0, 0, 0.336);
  color: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

#leaveCommunityBtn:hover {
  background-color: rgba(114, 255, 0, 0.8);
  color: #000;
  box-shadow: 0 0 8px rgba(255, 0, 0, 0.7);
}

#leaveCommunityBtn:active {
  transform: scale(0.95);
}

#communityTimer {
  position: fixed;
  top: calc(100px + 1em); /* Places it directly below TraderBanner with some spacing */
  left: 50%;
  transform: translateX(-50%); /* Centers the timer horizontally */
  width: 99%;
  max-width: 100%;
  padding: 8px;
  font-size: 0.8rem;
  color: #00ff00;
  background-color: rgba(0, 0, 0, 0.5);
  text-shadow: 0 0 5px #00ff00;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  text-align: center;
  z-index: 1001; /* Ensures it appears above other elements */
  transition: all 0.3s ease;
}

/* Mobile adjustments */
@media screen and (max-width: 600px) {
  #communityInterface {
  width: 99%;
  transform: translateX(-50%);
  bottom: 50px;
  padding: 6px;
  font-size: 0.75rem;
  }
  
  #communityInterface h2 {
  font-size: 0.8rem;
  }
  
  #leaveCommunityBtn {
  font-size: 0.75rem;
  padding: 5px;
  }
 #communityTimer {
   top: calc(60px + 1em); /* Adjust for smaller screens */
   font-size: 0.6rem;
   padding: 6px;
   }

#trader-name{
  width: 95%;
  margin: 60px 0px 0px 0px;
  overflow: hidden;
  margin-top: 5%;
  text-align: center;
  }
}

#goToDarkPoolMarketBtn::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 120%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: .7;
  transform: perspective(1.5em) rotateX(35deg) scale(1, .6);
}

#goToDarkPoolMarketBtn:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
  box-shadow: 0 0 1em .25em var(--glow-color),
        0 0 4em 2em var(--glow-spread-color),
        inset 0 0 .75em .25em var(--glow-color);
}

#goToDarkPoolMarketBtn:active {
  box-shadow: 0 0 0.6em .25em var(--glow-color),
        0 0 2.5em 2em var(--glow-spread-color),
        inset 0 0 .5em .25em var(--glow-color);
}

/* Go To Trade Button */
#goToTradeBtn {
  --glow-color: rgba(243, 246, 159, 0.821);
  --glow-spread-color: rgba(0, 255, 0, 0.781);
  --btn-color: #ff006a;
  --hover-bg-color: #ff00e6;
  --hover-text-color: #0303d2;

  padding: 10px;
  max-width: 100vw;
  width: 50%; /* Set width to 50% */
  box-sizing: border-box; /* Include padding and border in the width calculation */
  font-family: bithigh, sans-serif;
  letter-spacing: 4px;
  font-size: 1rem;
  background: var(--btn-color);
  color: var(--glow-color);
  touch-action: manipulation; /* Improve touch responsiveness */
  -webkit-tap-highlight-color: transparent; /* Disable tap highlight on iOS */
  position: fixed; /* Make the buttons fixed */
  bottom: 0; /* Align to the bottom */
  left: 0;
  z-index: 999; /* Ensure they stay on top */
  border: 2px solid var(--glow-color);
  border-radius: 3px;
  outline: none;
  box-shadow: 0 0 1em .15em var(--glow-color),
        0 0 2em .5em var(--glow-spread-color), /* Adjusted for less presence */
        inset 0 0 .55em .15em var(--glow-color),
        0 -0.5em 1em .1em rgba(255, 255, 255, 0.5); /* Light white glow towards the top */
  text-shadow: 0 0 .15em var(--glow-color);
  transition: all 0.3s;
}

#goToTradeBtn::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 100%; /* Adjust the top position for less presence */
  left: 0;
  height: 50%; /* Reduce the height to minimize the glow effect */
  width: 100%;
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: .5; /* Reduce opacity */
  transform: perspective(1.5em) rotateX(35deg) scale(1, .6);
}

#goToTradeBtn:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
  box-shadow: 0 0 1em .25em var(--glow-color),
        0 0 2em 1em var(--glow-spread-color), /* Adjusted for less presence on hover */
        inset 0 0 .75em .25em var(--glow-color),
        0 -0.5em 1em .1em rgba(255, 255, 255, 0.5); /* Maintain the lighter glow on hover */
}

#goToTradeBtn:active {
  box-shadow: 0 0 0.6em .25em var(--glow-color),
        0 0 2em 1em var(--glow-spread-color), /* Adjusted for less presence on active */
        inset 0 0 .5em .25em var(--glow-color),
        0 -0.5em 1em .1em rgba(255, 255, 255, 0.5); /* Maintain the lighter glow on active */
}

/* Filter Button */
#filterButton {
  --glow-color: rgba(243, 246, 159, 0.821);
  --glow-spread-color: rgba(0, 255, 0, 0.781);
  --btn-color: #7700ff;
  --hover-bg-color: #00ffc9;
  --hover-text-color: #0303d2;

  padding: 10px;
  max-width: 100vw;
  width: 50%; /* Set width to 50% */
  box-sizing: border-box; /* Include padding and border in the width calculation */
  font-family: bithigh, sans-serif;
  letter-spacing: 4px;
  font-size: 1rem;
  background: var(--btn-color);
  color: var(--glow-color);
  touch-action: manipulation; /* Improve touch responsiveness */
  -webkit-tap-highlight-color: transparent; /* Disable tap highlight on iOS */
  position: fixed; /* Make the buttons fixed */
  bottom: 0; /* Align to the bottom */
  right: 0;
  z-index: 999; /* Ensure they stay on top */
  border: 2px solid var(--glow-color);
  border-radius: 3px;
  outline: none;
  box-shadow: 0 0 1em .15em var(--glow-color),
        0 0 2em .5em var(--glow-spread-color), /* Adjusted for less presence */
        inset 0 0 .55em .15em var(--glow-color),
        0 -0.5em 1em .1em rgba(255, 255, 255, 0.5); /* Light white glow towards the top */
  text-shadow: 0 0 .15em var(--glow-color);
  transition: all 0.3s;
}

#filterButton::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 100%; /* Adjust the top position for less presence */
  right: 0;
  height: 50%; /* Reduce the height to minimize the glow effect */
  width: 100%;
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: .5; /* Reduce opacity */
  transform: perspective(1.5em) rotateX(35deg) scale(1, .6);
}

#filterButton:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
  box-shadow: 0 0 1em .25em var(--glow-color),
        0 0 2em 1em var(--glow-spread-color), /* Adjusted for less presence on hover */
        inset 0 0 .75em .25em var(--glow-color),
        0 -0.5em 1em .1em rgba(255, 255, 255, 0.5); /* Maintain the lighter glow on hover */
}

#filterButton:active {
  box-shadow: 0 0 0.6em .25em var(--glow-color),
        0 0 2em 1em var(--glow-spread-color), /* Adjusted for less presence on active */
        inset 0 0 .5em .25em var(--glow-color),
        0 -0.5em 1em .1em rgba(255, 255, 255, 0.5); /* Maintain the lighter glow on active */
}

/* Redeem Button */
#redeemButton {
  --glow-color: rgba(0, 255, 0, 0.739);
  --glow-spread-color: rgba(0, 255, 0, 0.781);
  --btn-color: #f70;
  --hover-bg-color: #00ffed;
  --hover-text-color: #d27802;

  padding: 10px;
  max-width: 100vw;
  width: 50%; /* Set width to 50% */
  box-sizing: border-box; /* Include padding and border in the width calculation */
  font-family: bithigh, sans-serif;
  letter-spacing: 4px;
  font-size: 1rem;
  background: var(--btn-color);
  color: var(--glow-color);
  touch-action: manipulation; /* Improve touch responsiveness */
  -webkit-tap-highlight-color: transparent; /* Disable tap highlight on iOS */
  position: fixed; /* Make the buttons fixed */
  bottom: 0; /* Align to the bottom */
  right: 0; /* Align to the right */
  z-index: 999; /* Ensure they stay on top */
  border: 2px solid var(--glow-color);
  border-radius: 3px;
  outline: none;
  box-shadow: 0 0 1em .15em var(--glow-color),
        0 0 2em .5em var(--glow-spread-color), /* Adjusted for less presence */
        inset 0 0 .55em .15em var(--glow-color),
        0 -0.5em 1em .1em rgba(255, 255, 255, 0.5); /* Light white glow towards the top */
  text-shadow: 0 0 .15em var(--glow-color);
  transition: all 0.3s;
}

#redeemButton::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 100%; /* Adjust the top position for less presence */
  left: 0;
  height: 50%; /* Reduce the height to minimize the glow effect */
  width: 100%;
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: .5; /* Reduce opacity */
  transform: perspective(1.5em) rotateX(35deg) scale(1, .6);
}

#redeemButton:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
  box-shadow: 0 0 1em .25em var(--glow-color),
        0 0 2em 1em var(--glow-spread-color), /* Adjusted for less presence on hover */
        inset 0 0 .75em .25em var(--glow-color),
        0 -0.5em 1em .1em rgba(255, 255, 255, 0.5); /* Maintain the lighter glow on hover */
}

#redeemButton:active {
  box-shadow: 0 0 0.6em .25em var(--glow-color),
        0 0 2em 1em var(--glow-spread-color), /* Adjusted for less presence on active */
        inset 0 0 .5em .25em var(--glow-color),
        0 -0.5em 1em .1em rgba(255, 255, 255, 0.5); /* Maintain the lighter glow on active */
}

@media screen and (max-width: 768px) {
  #goToTradeBtn, #filterButton, #redeemButton {
    height: 50px;
  }
  
  #goToTradeBtn {
    left: 0;
    right: initial; /* Reset right position */
  }

  #redeemButton {
    right: 0;
    left: initial; /* Reset left position */
  }
}

#filter-section {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.7), 0 0 30px rgba(0, 255, 0, 0.7), 0 0 40px rgba(0, 255, 0, 0.7), 0 0 50px rgba(0, 255, 0, 0.7);
  z-index: 9999;
  width: 95%;
  max-width: 95%;
}

#filter-section input,
#filter-section select {
  display: block;
  width: 90%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #00ff00;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #00ff00;
  box-shadow: inset 0 0 10px rgba(0, 255, 0, 0.7);
}

#filter-section button {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #00ff00;
  border: 2px solid #00ff00;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.7);
  transition: all 0.3s ease;
}

#filter-section button:hover {
  background-color: rgba(0, 255, 0, 0.9);
  color: #000000;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.9);
}

/* Common styling for all tables */
/* General table styles */
table {
  width: 100%;
  border-collapse: collapse;
  color: #00ff00; /* neon green text */
  text-align: center;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.5); 
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.2) 10%, transparent 10%);
  background-size: 10px 10px; /* Size of the circles */
  font-family: 'bithigh';
  display: block;
}

th, td {
  padding: 5px;
  word-wrap: break-word;
  background-color: transparent; /* Clear backgrounds for cells */
}

th {
  background-color: #0f1e09; /* Dark background for headers */
}

tr {
  /*scroll-snap-align: start;*/
  height: calc(90vh - 5px); /* Each row's height takes up the remaining viewport height */
  display: flex;
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
}

/* Responsive design for smaller screens */
@media screen and (max-width: 2600px) {
  thead {
    display: none; /* Hide headers on small screens */
  }
  th, td {
    display: block; /* Stack the cells vertically within each row */
    text-align: center; /* Center text for better readability on small devices */
    padding: 5px; /* Adjust padding as needed */
    
    font-size: 14px; /* Smaller font size */
    color: #00ff00; /* Green font color */
  }
  
  table, tbody, tr, td {
    display: block;
    width: 100%;
    font-size: 14px; /* Same font size as td */
    
  }
  
  td {
    display: block; /* Make each td a block for individual styling */
    width: 100%; /* Full width for mobile view */
    text-align: center;
    padding: 10px;
    position: relative;
    font-size: 14px; /* Fixed font size */
    margin-bottom: 15px; /* Space between rows */
    padding: 10px;
    box-sizing: border-box; /* Include padding and border in the element's width */
    background-color: rgba(0, 102, 0, 0.3); /* Transparent green background */
  }
  
  td:before {
    content: attr(data-title);
    font-weight: bold;
    color: #fff; /* White font color */
    background-color: rgba(0, 255, 0, 0.5); /* Transparent green background */
    display: block;
    padding: 5px;
    margin-bottom: 10px; /* Space between header and data */
    text-align: center;
    position: relative;
    z-index: 1;
    border-radius: 4px; /* Rounded corners */
    font-size: .9rem;
  }
  
  td[data-title="Points"]::before {
    content: "Value";
  }
  td[data-title="Points"],
  td[data-title="Quantity"],
  td[data-title="Location"],
  td[data-title="#Shares"],
  td[data-title="Owner"] {
    font-size: .9rem;
    float: left; 
    width: 50%;
    box-sizing: border-box;
    margin: 0 auto; 
  }
  
.stock-row {
    
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .stock-row td:before {
    content: attr(data-title);
    font-weight: bold;
    color: rgba(255, 0, 107, 0.81);
    background-color: rgba(0, 123, 255, 0.5); 
    display: block;
    padding: 5px;
    margin-bottom: 10px; 
    text-align: center;
    position: relative;
    z-index: 1;
    border-radius: 3px; 
    font-size: .9rem;
  }
  
  /* Styled Select Container */
  .styled-select {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 300px;
    margin: 10px auto;
    border: 2px solid #00ff00; /* Bright green border */
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.8); /* Dark background */
    box-shadow: 0 0 10px rgba(0, 255, 0, 0.5); /* Green glow */
  }
  
  /* Styled Select Element */
  .styled-select select {
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    color: #00ff00; /* Bright green text */
    font-family: 'bithigh', sans-serif;
    font-size: 16px;
    background-color: transparent; /* Transparent background */
    border: none; /* Remove default border */
    appearance: none; /* Remove browser default styling */
    -webkit-appearance: none; /* For Safari */
    -moz-appearance: none; /* For Firefox */
    outline: none; /* Remove outline */
    cursor: pointer;
  }
  
  /* Arrow for Select */
  .styled-select::after {
    content: "▼";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #00ff00; /* Bright green arrow */
    pointer-events: none; /* Allow click through */
  }
  
  /* Hover Effect */
  .styled-select:hover {
    box-shadow: 0 0 15px rgba(0, 255, 0, 0.7); /* Enhanced green glow */
  }


.stock-row td[data-title="#Shares"],
.stock-row td[data-title="Description"],
.stock-row td[data-title="Owner"]{
  color: rgba(255, 0, 107, 1);
}
  @keyframes backgroundFadeITBY {
    0% { background-color: rgba(39, 255, 0, 1); }
    50% { background-color: rgba(39, 255, 0, 0.5); }
    100% { background-color: rgba(39, 255, 0, 1); }
  }

  @keyframes backgroundFadeITBYS {
    0% { background-color: rgba(123, 150, 243, 1); }
    50% { background-color: rgba(1, 57, 251, 0.5); }
    100% { background-color: rgba(123, 150, 243, 1); }
  }
  
  .stock-row td {
    background-color: rgba(1, 57, 251, 0.3); /* Light blue background */
  }

  td[data-title="Company Name"],
  td[data-title="Value"],
  td[data-title="Shorted By"] {
      clear: both;
      font-size: 1.4rem;
      padding: 0;
      margin: 0 auto; 
      color: rgba(255, 0, 107, 1);
    }
  
  td[data-title="Ask"],
  td[data-title="Bid"],
    td[data-title="Short"] {
      font-size: 1rem;
      float: left;
      width: 50%;
      box-sizing: border-box;
      background: rgba(1, 251, 244, 0.8); /* Initial background color */
      animation: backgroundFadeITBYS 3s infinite; /* Apply the animation */
    }
  
  /* Hide the cells for 'Trade' and 'Buy' */
    td[data-title="Ask"]::before, 
    td[data-title="Bid"]::before, 
    td[data-title="Short"]::before {
      content: attr(data-title);  
      visibility: hidden;         
      height: 0;                 
    }
    .space-short{
      margin: 0;
    }
    .name-short,
    .shares-short,
    .value-short {
      font-size: 0.8rem;
      width: 33.33%;
      height: 50px;
      box-sizing: border-box;
      background: rgba(2, 26, 109, 0.6);
      padding: 2px;
      align-content: center;
      
    }
    
    #items-list td[data-title="Shorted By"] table tr {
      margin-top: 0;
    }
    td[data-title="Shorted By"] table:last-child {
      margin-bottom: 80px;
    }
    td[data-title="Shorted By"] table {
      display: block;
      position: relative;
      float: left;
      width: 100%;
      max-width: 100%;
      height: 50px;
      margin: 0 0 10px 0;
    }	
    
    td[data-title="Shorted By"] td:before {
    display: none;
    }	
    

  td[data-title="Shorted By"] td {
    display: block;
    position: inherit;
    float: left;


  }
  
  td[data-title="Trade"],
  td[data-title="Buy"] {
    font-size: 1rem;
    float: left;
    width: 50%;
    box-sizing: border-box;
    background: rgba(39, 255, 0, 1); /* Initial background color */
    animation: backgroundFadeITBY 3s infinite; /* Apply the animation */
  }
  
  
/* Hide the cells for 'Trade' and 'Buy' */
  td[data-title="Trade"]::before, 
  td[data-title="Buy"]::before {
    content: attr(data-title);  
    visibility: hidden;         
    height: 0;                 
  }
  td[data-title="Item"] {
    clear: both; 
    font-size: 1.4rem;
    margin: 0 auto; 
  }

  td[data-title="Description"],
  td[data-title="Redeem"] {
    font-size: 1rem;
    clear: both;
    margin: 0 auto; 
  }

}

.buyButton{
  padding:10px;
  width: 100%;
  border: 1px solid #00ff00;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  font-size: 14px;
  background: #006600;
  color: #00ff00;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border-radius: 5px; /* Rounded corners */
}

.buyButton:hover {
  background: #00ff00;
  color: #0303d2;
}


.stockButton{
  padding:10px;
  width: 100%;
  border: 1px solid rgba(1, 251, 244, 0.8);
  margin: 0 auto;
  font-family: Arial, sans-serif;
  font-size: 14px;
  background: rgba(1, 57, 251, 0.5);
  color: #ff006a;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border-radius: 5px; /* Rounded corners */
}

.stockButton:hover {
  background: #00ff00;
  color: #0303d2;
}

.tradeButton{
  padding:10px;
  width: 100%;
  border: 1px solid #00ff00;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  font-size: 14px;
  background: #006600;
  color: #00ff00;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border-radius: 5px; /* Rounded corners */
}

.tradeButton:hover {
  background: #00ff00;
  color: #0303d2;
}


/*  styling for all Elements market */

#stock-list tr:last-child {
  border-bottom: none;
}

#stock-list td {
  padding: 5px;
  word-wrap: break-word;
  background-color: transparent;
}

#stock-list td[data-title="Stock"] {
  font-size: 1.4rem;
  margin: 0 auto;
}

#stock-list td[data-title="Description"] {
  font-size: 1rem;
  clear: both;
  margin: 0 auto;
}

#stock-list td[data-title="Shares"],
#stock-list td[data-title="Price"] {
  font-size: .9rem;
  float: left;
  width: 50%;
  box-sizing: border-box;
  margin: 0 auto;

}

#stock-list td[data-title="Trade"] {
  font-size: 1rem;
  float: left;
  width: 50%;
  box-sizing: border-box;
  background: rgba(1, 124, 251, 0.8);
  animation: backgroundFadeITBY 3s infinite;
}

.sharesTrad {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sharesTrad strong {
  display: block;   
  text-align: center;
  margin-bottom: 5px; 
  min-width: 97%;
  width: 100%;
}

.sharesTrad span {
  display: block; /* Ensure span is a block-level element */
  text-align: center;
  width: 100%;
}



#shorted-stocks-container {
  margin-bottom: 50px;
  height: 100%;
}
#stock-holdings-container, #shorted-stocks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

#short-positions-container{
  margin-bottom: 50px;
  width: 100%;
}
#shorted-stocks-list{
  background: rgba(248, 146, 60, 0.85);
  width: 90%;
  border: 3px solid rgba(255, 0, 0, 0.86);
}
.close-short-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: rgba(255, 0, 0, 0.892);
  color: #000000;
  border: 2px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'bithigh', Arial, sans-serif;
  font-size: 16px;
  transition: all 0.3s ease;
  box-shadow: 0 0 5px rgba(255, 165, 0, 0.5);
}

.close-short-button:hover {
  background-color: rgba(0, 255, 0, 0.474);
  box-shadow: 0 0 10px rgba(255, 165, 0, 0.7);
}


#steal-button-container,
#delete-portfolio-button-container,
#ransom-button-container,
#create-community-button-container,
#LBOButton-button-container,
#SubsidyButton-button-container {
  display: none; /* Default to hidden until explicitly shown */
  text-align: center;
  margin-top: 20px;
  position: relative;
  z-index: 1000;
}
#userSearchInput,
#poolNameInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(0, 255, 0, 0.5);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #00ff00;
  font-size: 1rem;
  box-shadow: inset 0 0 5px rgba(0, 255, 0, 0.5);
}
/* User List */
.popup-user-list {
  max-height: 300px; /* Restrict height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid rgba(0, 255, 0, 0.5);
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  scrollbar-width: thin; /* For Firefox: thin scrollbar */
  scrollbar-color: rgba(0, 255, 0, 0.5) rgba(0, 0, 0, 0.5); /* For Firefox: scrollbar color */
}

#userList {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid rgba(0, 255, 0, 0.5);
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 255, 0, 0.5) rgba(0, 0, 0, 0.5);
}

#userList label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.8rem;
  cursor: pointer;
}
#createCommunityButton {
    width: 98%;
  max-width: 600px; /* Adjust as needed */
  height: auto;
  /*border: 2px solid rgba(150, 0, 113, 0.7);*/
  background-color: rgba(0, 0, 0, 0.9);
  color: #00ff00;
  font-family: bithigh, sans-serif;
  letter-spacing: 2px;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1000;
}

#stealButton {
    width: 98%;
    max-width: 600px; /* Adjust as needed */
    height: auto;
    /*border: 2px solid rgba(150, 0, 113, 0.7);*/
    background-color: rgba(0, 0, 0, 0.9);
    color: #00ff00;
    font-family: bithigh, sans-serif;
    letter-spacing: 2px;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 3px;
    transition: all 0.3s ease;
    position: relative;
    z-index: 1001;
  }

 #deletePortfolioButton, #ransomButton, #LBOButton, #SubsidyButton {
    width: 98%;
    max-width: 600px; /* Adjust as needed */
    height: auto;
    /*border: 2px solid rgba(0, 81, 150, 0.7);*/
    background-color: rgba(0, 0, 0, 0.9);
    color: #00ff00;
    font-family: bithigh, sans-serif;
    letter-spacing: 2px;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 3px;
    transition: all 0.3s ease;
    position: relative;
    z-index: 1001;
  }


#stealButton:hover {
  background-color: #00ff00; /* Neon green background on hover */
  color: #000000; /* Black text on hover */
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.7), 0 0 20px rgba(0, 255, 0, 0.7); /* Enhanced glow effect */
}

#deletePortfolioButton:hover {
  background-color: #ff0000; /* Neon green background on hover */
  color: #000000; /* Black text on hover */
  box-shadow: 0 0 10px rgba(255, 0, 107, 1), 0 0 20px rgba(235, 0, 254, 0.95); /* Enhanced glow effect */
}

#ransomButton:hover {
  background-color: rgba(250, 254, 0, 0.95); /* Neon green background on hover */
  color: #000000; /* Black text on hover */
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.86), 0 0 20px rgba(235, 0, 254, 0.95); /* Enhanced glow effect */
}

#createCommunityButton:hover  {
  background-color: rgba(2, 26, 109, 0.6); /* Neon green background on hover */
  color: #ffffff; /* Black text on hover */
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.474), 0 0 20px rgba(0, 255, 0, 0.849); /* Enhanced glow effect */
  
}
#confirmSelectionMU, #sendMessageBtn{
  width: 90%; 
  max-width: 90%;
padding: 10px 20px;
cursor: pointer;
font-family: 'bithigh', sans-serif;
  font-size: 0.7rem; 
color: #3aff00;
background: rgba(0, 255, 40, 0.42);
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.7);
border-bottom: 3px solid #6fffb5;
border-top: 2px solid #88ff6f;
border-right: 3px solid #ff6f6f;
border-left: 2px solid #fffe6f;
 transition: all 0.3s ease;
 width: calc(45% - 10px);
 margin: 5px; 
 display: inline-block;
 box-shadow: inset 0 0 10px rgba(0, 255, 0, 0.7); 
}
#cancelSelectionMU, #closePopupBtn{
  width: 90%; 
  max-width: 90%;
padding: 10px 20px;
cursor: pointer;
font-family: 'bithigh', sans-serif;
  font-size: 0.7rem; 
color: #1f0404;
background: rgba(255, 0, 0, 0.42);
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.7);
border-bottom: 3px solid #ff6f6f;
border-top: 2px solid rgba(255, 0, 0, 0.42);
border-right: 3px solid rgba(255, 0, 0, 0.42);
border-left: 2px solid #89ff6f;
 box-shadow: inset 0 0 10px rgba(255, 0, 0, 0.7); 
 transition: all 0.3s ease;
 width: calc(45% - 10px);
 margin: 5px; 
 display: inline-block;
}
.selButMuCon{
  width: 100%;
  max-width: 100%;
}

#userSelectMU {
  appearance: none; /* Remove browser default styling */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  color: #0f0; /* Bright green for text */
  background-color: rgba(0, 0, 0, 0.85); /* Flat dark background */
  border: 2px solid #0f0; /* Bright green border */
  border-radius: 5px; /* Smooth corners */
  width: 90%;
  max-width: 90%;
  height: 40px;
  max-height: 50px;
  margin: 10px auto;
  padding: 5px 10px; /* Adjust padding for text alignment */
  font-size: 0.9rem;
  font-family: 'bithigh', sans-serif;
  text-align: center;
  text-align-last: center; /* Center-align the dropdown text */
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.4); /* Subtle glow effect */
  transition: all 0.3s ease; /* Smooth transitions */
  cursor: pointer; /* Pointer for interactivity */
}

/* Add a custom dropdown arrow */
#userSelectMU::after {
  content: ''; /* Empty content for the arrow */
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  border: 6px solid transparent; /* Triangle shape */
  border-top-color: #0f0; /* Bright green arrow */
  pointer-events: none; /* Prevent interfering with clicks */
}

/* Hover effect */
#userSelectMU:hover {
  background-color: rgba(0, 255, 0, 0.1); /* Subtle hover effect */
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.6); /* Intensified glow */
  transform: scale(1.02); /* Slight hover scaling */
}


#multiUserSelectionPopup {
  width: 95%;
  max-width: 95%;
  height: 95%;
  max-height: 95%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.85);
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  color: #00ff00;
  font-family: 'bithigh', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically centers content */
  align-items: center; /* Horizontally centers content */
  text-align: center;
  overflow: auto; /* Allows scrolling if content overflows */
}


#multiUserSelectionPopup h2 {
  margin: 0 0 10px 0;
  color: #00ff00;
  font-size: 1.5rem;
}
#confirmSelectionMU:hover {
  background-color: #00ff00; 
}

#cancelSelectionMU {
  background-color: rgba(255, 0, 0, 0.7); /* Light red background */
}

#cancelSelectionMU:hover {
  background-color: #ff0000; /* Bright red background on hover */
}

.holding-stocks, .shorted-stock {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 5px;

  text-align: center;  
}

.video-background {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  z-index: -1;
  pointer-events: none; 
  user-select: none; 
  background-size: cover;
  background-position: center;
}

#market-background,
#stockBidBackground,
#redeem-background,
#redeemPopupBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover; 
  background-position: center; 
  z-index: -1; 
  pointer-events: none;
  user-select: none; 
  background-image: radial-gradient(black 1px, transparent 1px);
  background-size: 5px 5px;
}

.CompanyName{
  margin: 70px 0 0 0;
}
.marketNameItem {
  margin: 70px 0 0 0;
}
#items-list tr {
  margin-top: 30px;
}

#stock-list tr {
  margin-top: 30px; 
  margin-bottom: -50px;
}

#market-items {
  padding-top: 20px; 
  padding-bottom: 60px; 
  position: relative;
  width: 100%;
  margin: 0px 0px 0px 0px;

}


input[type="checkbox"]:checked {
  animation: blink 1s infinite; 
}

/* Define the animation for blinking */
@keyframes blink {
  0% { background-color: red; }
  50% { background-color: #27ff00; color: red; }
  100% { background-color: red; }
}

.marketNameItem.checked {
  animation: blink 2s infinite;  /* Ensures the animation loops infinitely */
}

/* Rest of the CSS styles remain the same */
input[type="checkbox"] {
  /* Make the checkbox bigger */
  width: 30px;
  height: 30px;
  /* Hide the default checkbox appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add custom checkbox design */
  background-color: blue; /* Blue background for unchecked */
  border: 2px solid #00ff00; /* Neon green border */
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

/* Custom styling for the checkbox checkmark */
input[type="checkbox"]::after {
  content: "\2713"; /* Unicode character for checkmark */
  font-size: 20px;
  color: transparent; /* Transparent checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the checkmark */
}

input[type="checkbox"]:checked::after {
  color: #00ff00; /* Neon green checkmark */
  background: transparent;
}

.offerBut{
  padding:10px;
  width: 100%;
  border-collapse: collapse;
  background-color: rgba(0, 102, 0, 0.3); /* Transparent green background */
  font-family: bithigh, sans-serif;
  letter-spacing: 4px;
  font-size: 1.5rem;
  background: #3dd201;
  color:#00ff00;
}

.offerBut:hover {
  background: #00ff00; /* Change background color to complementary */
  color: #d202c9; /* Change text color to complementary */
}

/*  styling for all Elements transaction */
#item-name{
  color: #00ff00;
}
#portfolio-name{
  color: #00ff00;
}

.transIm {
  text-align: center;
  color: #00ff00;
  background-color: rgba(0, 40, 0, 0.95);
  border: 2px solid #00ff00;
  border-radius: 3px;
  padding: 5px;
  margin: 85px 0 0 0;
  overflow: hidden;
}

.animatedItNa {
  margin: 10px 0;
}

.animatedItNa p {
  margin: 0;
  color: #00ff00;
}

.trans-led-board {
  margin: 5px 0;
}

#item-id, #owner-id {
  display: none;
}








/*--css for redeem.html--*/


@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes textAnimation {
  0% {
    color: #00ff00;
  }
  50% {
    color: #e0f;
  }
  100% {
    color: #00ff00;
  }
}

#items-container {
  text-align: center;
  width: 100%;
  max-width: 100vw;
  margin: 55px auto;
}

.redeemAsk {
  border: 3px solid #00ff00; /* Simplified uniform border */
  padding: 15px;
  max-width: 90%;
  margin: 10px auto;
  background-color: rgba(0, 100, 0, 0.75); /* Darker green transparent background with 0.75 opacity */
  color: #00ff00; /* Consistent green text for retro effect */
  font-family: 'bithigh', Arial, sans-serif;
  animation: none; /* Remove complex animations for retro simplicity */
  position: relative; /* Position relative to the parent container */
  z-index: 998; /* Ensure the content stays below the RedeemBanner */
}
.RedeemHold{
  height: 50px;
  width: 90%;
}

#sendRedeemOfferButton {
  display: none; /* Initially hide the button */
  margin: 0;
  padding: 15px;
  width: 100%; /* Match the width of other elements */
  border: 4px solid #00ff00; /* Uniform green border */
  font-family: 'bithigh', Arial, sans-serif;
  letter-spacing: 2px; /* Subtle letter spacing for a digital look */
  font-size: 18px; /* Larger font size for readability */
  background: rgba(254, 153, 0, 0.95); /* Darker green transparent background with 0.75 opacity */
  color: #00ff00; /* Green text for high contrast */
  cursor: pointer;
  position: fixed; /* Make the button fixed */
  bottom: 50px; 
  left: 50%; 
  transform: translateX(-50%); 
  z-index: 1000; 
}

#sendRedeemOfferButton:hover {
  background: #00ff00;
  color: black;
}


#holdings-list input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #00ff00;
  background-color: rgba(0, 100, 0, 0.75); /* Darker green transparent background with 0.75 opacity */
  cursor: pointer;
  margin-right: 10px;
}

#holdings-list input[type="radio"]:checked {
  background-color: #00ff00;
  border-color: black;
}



#holdings-list label {
  font-size: 18px;
  color: #00ff00;
  cursor: pointer;
}

.holdings-redeem-list {
  display: flex;
  flex-direction: column;
  border: 4px solid #00ff00;
  color: #00ff00;
  font-family: 'bithigh', Arial, sans-serif;
  background-color: rgba(0, 100, 0, 0.75);
  padding-bottom: 100px;
  max-height: calc(100vh - 200px);
  overflow-y: auto; /* Enable vertical scrolling */
}

.header-row {
  display: flex;
  flex-wrap: nowrap;
  border-top: 5px solid #00ff00;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cell {
  border: 2px solid #00ff00;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: #00ff00;
}

.radio-cell {
  flex: 0.5;
}

.item-name-cell {
  flex: 3;
}

.points-cell {
  flex: 1;
}

@keyframes blinkRED {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.row.blinkRED {
  animation: blink 1s infinite;
}





#acceptOffer {
  margin: 5px;
  padding: 10px;
  width: 100%;
  border-collapse: collapse;
  border-left: 2px solid rgba(0, 255, 0, 0.849);
  border-right: 5px solid rgba(0, 255, 0, 0.849);
  border-top: 2px solid rgba(0, 255, 0, 0.849);
  border-bottom: 5px solid rgba(0, 255, 0, 0.849);
  font-family: 'bithigh', sans-serif;
  letter-spacing: 4px;
  font-size: 1.5rem;
  background: rgba(0, 255, 0, 0.75);
  color: #050129;
  box-shadow: 0 0 5px rgba(39, 255, 0, 0.8), 0 0 10px rgba(39, 255, 0, 0.8); /* Neon glow effect */
  transition: background 0.3s ease, color 0.3s ease; /* Smooth transition */
}

#acceptOffer:hover {
  background: #00ff00; /* Change background color on hover */
  color: #000000; /* Change text color on hover */
}

#declineOffer {
  margin: 5px;
  padding: 10px;
  width: 100%;
  border-collapse: collapse;
  border-left: 2px solid rgba(255, 0, 0, 0.634);
  border-right: 2px solid rgba(255, 0, 0, 0.634);
  border-top: 2px solid rgba(255, 0, 0, 0.634);
  border-bottom: 3px solid rgba(255, 0, 0, 0.634);
  font-family: 'bithigh', sans-serif;
  letter-spacing: 4px;
  font-size: 1.5rem;
  background: rgba(255, 0, 0, 0.75);
  color: #00ff00;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.8), 0 0 10px rgba(255, 0, 0, 0.8); /* Neon glow effect */
  transition: background 0.3s ease, color 0.3s ease; /* Smooth transition */
}

#declineOffer:hover {
  background: #ff6666; /* Change background color on hover */
  color: #000000; /* Change text color on hover */
}

#buyOfferModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
   width: 90%; 
   max-width: 600px; 
   height: 90%; 
   max-height: 90%; 
  background-color: rgba(0, 0, 0, 0.85);
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #00ff00;
  box-shadow: 0 4px 8px rgba(0, 255, 0, 0.3);
  color: #00ff00;
  font-family: 'bithigh', Arial, sans-serif;
  text-align: center;
  align-content: center;
  z-index: 10001;
}

#buyOfferModal input[type="number"] {
  width: calc(100% - 24px);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #00ff00;
  background-color: #000;
  color: #00ff00;
}

#buyOfferModal button {
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'bithigh', sans-serif;
  color: #1f00ff;
  background: #00ff28;
  margin: 5px;
}

#offerCancelButton{
  background: rgba(255, 0, 0, 0.85);
  background-color: rgba(255, 0, 0, 0.85);
}
.tradeOffPop {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%); 
   width: 90%; 
   max-width: 600px; 
   height: auto; 
   max-height: 90%; 
   padding: 20px;
   background-color: rgba(0, 8, 36, 0.774); 
   border-radius: 10px; 
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
   z-index: 9999;
   font-family: 'bithigh', Arial, sans-serif;
   color: #00ff00;
   text-align: center; 
   align-content: center;
}

@media (max-width: 600px) {
  .tradeOffPop {
  width: 95%; /* Ensure it takes almost full width on small screens */
  max-width: 95%;
  }
}
.tradeOffPop button {
  margin: 5px; 
  padding: 10px; 
  width: 100%; 
  border-left: 2px solid #6f83ff; 
  border-right: 8px solid #6f83ff; 
  border-top: 2px solid #6f83ff; 
  border-bottom: 9px solid #6f83ff; 
  font-family: 'bithigh', sans-serif; 
  letter-spacing: 4px; 
  font-size: 1.5rem;
}

.RedeemBanner {
  max-width: 100vw;
  width: 100%;
  margin: 0 auto; /* Adjust margin */
  text-align: center;
  position: fixed; /* Make the banner fixed */
  top: 0;
  left: 0;
  right: 0;
  z-index: 999; /* Ensure the banner stays above the background */
  height: auto;
}

.RedeemBanner img {
  width: 100%;
  height: auto;
  display: block;
  
}
.TraderBanner {
  max-width: 100vw;
  width: 100%;
  margin: 0 auto; /* Adjust margin */
  text-align: center;
  position: fixed; /* Make the banner fixed */
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999; /* Ensure the banner stays above the background */
  height: auto;
}

.TraderBanner img {
  width: 100%;
  height: auto;
  max-height: auto;
  display: block;
}
#TraderBanner , #marketBanner , #RedeemBanner , #stockBanner{
  width: 100%; 
  max-width: 100%; 
  height: auto; 
  object-fit: 
  contain; 
  pointer-events:none;
}
#economic-triggers-message {
  margin: 15px 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  text-align: center;
}

#economic-triggers-message p {
  color: #fcba03;
  font-family: 'bithigh', monospace;
  font-size: 18px;
  margin: 0;
}
.tradeOffPop h2 {
  margin-top: 0;
}

.tradeOffPop p {
  margin-bottom: 20px;
}

.tradeOffPop #timer {
  font-size: 1.5rem;
  text-align: center;
}
.alOffTr{
  padding: 20px;
  background-color: rgba(0, 8, 36, 0.743);
  border-left: 2px solid #ff7f6e;
  border-right: 8px solid #6f83ff; 
  border-top: 2px solid #ff6efd; 
  border-bottom: 9px solid #ff0000;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-family: bithigh, sans-serif;
}



.transactionBanner {
  max-width: 100vw;
  width: 100%;
  margin: 0 auto; /* Adjust margin */
  text-align: center;
  position: fixed; /* Make the banner fixed */
  top: 0;
  left: 0;
  right: 0;
  z-index: 999; /* Ensure the banner stays above the background */
  height: auto;
}

.transactionBanner img {
  width: 100%;
  height: auto;
  display: block;
}

#holdings-list2 {
  width: 100%;
  background-color: rgba(0, 0, 100, 0.3);
  padding: 10px;
  box-sizing: border-box;
}

.transaction-row {
  display: flex;
  margin-bottom: 5px;
}

.transaction-cell {
  flex: 1;
  padding: 10px;
  text-align: left;
  overflow-x: auto;
  white-space: nowrap;
}

/* Different shades of blue for each cell */
.transaction-cell:nth-child(1) { background-color: rgba(0, 50, 150, 0.7); }
.transaction-cell:nth-child(2) { background-color: rgba(0, 75, 175, 0.7); }
.transaction-cell:nth-child(3) { background-color: rgba(0, 100, 200, 0.7); }
.transaction-cell:nth-child(4) { background-color: rgba(0, 125, 225, 0.7); margin-bottom: 50px; }

.transCellTit {
  font-weight: bold;
  color: #aaf;
  display: block;
  margin-bottom: 5px;
}

/* Button styling */
.offerBut {
  background-color: #f82;
  color: rgba(0, 81, 150, 0.7);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.offerBut:hover {
  background-color: #00f;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .transaction-row {
  flex-direction: column;
  }
  
  .transaction-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  color: white;
  text-align: left;
  overflow-x: auto;
  }

  .transCellTit {
  margin-bottom: 0;
  margin-right: 10px;
  min-width: 90px; /* Ensures title doesn't wrap */
  }

  .transaction-cell > span:last-child {
  flex: 1;
  text-align: left;
  overflow-x: auto;
  white-space: nowrap;
  padding-right: 10px; /* Space for scrollbar */
  }

  .transaction-cell:last-child {
  justify-content: flex-start;
  }

  .offerBut {
  width: 100%;
  }

  /* Hide header row on mobile */
  .transaction-row.header {
  display: none;
  }

  /* Style scrollbars for webkit browsers */
  .transaction-cell::-webkit-scrollbar {
  height: 3px;
  }

  .transaction-cell::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  }
}



#stock-transaction-container {
  margin-top: 180px;
  background-color: rgba(0, 8, 36, 0.86); /* Dark background */
  border: 2px solid #00ff00; /* Neon green border */
  padding: 20px;
  border-radius: 10px;
  color: #00ff00; /* Neon green text */
  text-align: center;
}

#stock-transaction-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-shadow: 0 0 10px #00ff00;
}

#stock-transaction-container p {
  font-size: 18px;
  margin: 10px 0;

}

#stock-transaction-container input[type="number"] {
  width: calc(100% - 20px); /* Full width minus padding */
  padding: 10px;
  margin: 10px 0;
  background-color: rgba(51, 51, 51, 0.736); /* Dark input background */
  border: 2px solid #00ff00; /* Neon green border */
  color: #00ff00; /* Neon green text */
  border-radius: 5px;

  font-size: 16px;
}

#stock-transaction-container button {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 20px 0;
  background-color: rgba(0, 255, 0, 0.65);
  border: 2px solid #00ff00;
  color: #0d0d0d; 
  font-size: 18px;
  border-radius: 3px;
  cursor: pointer;

}

#stock-transaction-container button:hover {
  background-color: rgba(0, 255, 0, 0.474);
  color: #00ff00; 
  border: 2px solid #00ff00; 

}

#stockBidPopup {
  position: fixed;
  width: 90%;
  max-width: 600px;
  max-height: 80vh; /* Set a max height for mobile screens */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.9);
  padding: 15px; /* Reduced padding for better fit */
  z-index: 10000;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #00ff00;
  font-family: 'bithigh', sans-serif;
  text-align: center;
  border: 2px solid #00ff00;
  box-shadow: 0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00;
  overflow-y: auto; /* Enable scrolling if content exceeds max height */
}

@media (max-width: 600px) {
  #stockBidPopup {
  width: 95%; /* Ensure it takes almost full width on small screens */
  max-width: 95%;
  }
}

#stockBidPopup h2 {
  font-size: 1rem; 
  margin-bottom: 10px;
  color: #00ff00; 
  text-align: center; 
  text-shadow: 0 0 1px #00ff00, 0 0 2px #00ff00; 
}

#stockBidPopup p {
  font-size: 0.8rem;
  color: #00ff00; 
  margin-bottom: 20px;
}

#acceptBid, #declineBid {
  width: calc(45% - 10px);
  padding: 10px;
  font-size: 0.8rem;
  margin: 5px;
  border: 2px solid #00ff00;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.7), 0 0 10px rgba(0, 255, 0, 0.7); /* Outer glow effect */
}

#acceptBid {
  float: left;
  background-color: rgba(0, 255, 0, 0.7);
  color: black;
}

#acceptBid:hover {
  background-color: #00ff00; 
  color: #000000; 
}

#declineBid {
  float: right;
  background-color: rgba(255, 0, 0, 0.7);
  color: #00ff00;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.7), 0 0 10px rgba(255, 0, 0, 0.7); /* Red glow effect */
}

#declineBid:hover {
  background-color: #ff6666; 
  color: #000000; 
}

#bidTimer {
  color: #00ff00; 
  margin-top: 10px;
  font-size: 0.8rem;
}
/* Offer Sent Popup Overlay */
#OF2-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Offer Sent Popup */
#OF2-offerSentPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: rgba(1, 32, 59, 0.7);
  padding: 20px;
  border-radius: 3px;
  border: 2px solid #00ff00;
  color: #00ff00;
  font-family: 'bithigh', Arial, sans-serif;
  text-align: center;
  z-index: 10001;
}

/* Trade Offer Popup */
#OF2-tradeOfferPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95vw;
  max-width: 100vw;
  height: 95vw;
  max-height: 95vw;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  font-family: 'bithigh', Arial, sans-serif;
  color: #00ff00; 
  align-content: center;
}

/* Trade Offer Popup Buttons */
#OF2-tradeOfferPopup button {
  margin: 5px;
  padding: 10px;
  width: 100%;
  font-family: 'bithigh', Arial, sans-serif;
  letter-spacing: 2px;
  font-size: 1.5rem;
  border-radius: 5px;
  transition: background 0.3s ease, color 0.3s ease; /* Smooth transition */
  cursor: pointer;
}

#OF2-acceptOffer {
  background: rgba(0, 255, 0, 0.5);
  color: #050129;
  border-left: 2px solid rgba(0, 255, 0, 0.95);
  border-right: 2px solid rgba(0, 255, 0, 0.95);
  border-top: 2px solid rgba(0, 255, 0, 0.95);
  border-bottom: 3px solid rgba(0, 255, 0, 0.95);
  box-shadow: 0 0 5px rgba(39, 255, 0, 0.8), 0 0 10px rgba(39, 255, 0, 0.8); /* Neon glow effect */
}

#OF2-acceptOffer:hover {
  background: #00ff00;
  color: #000000; 
}

#OF2-declineOffer {
  background: rgba(255, 0, 0, 0.5);
  color: #00ff00;
    border-left: 2px solid rgba(255, 0, 0, 0.95);
  border-right: 2px solid rgba(255, 0, 0, 0.95);
  border-top: 2px solid rgba(255, 0, 0, 0.95);
  border-bottom: 3px solid rgba(255, 0, 0, 0.95);
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.8), 0 0 10px rgba(255, 0, 0, 0.8);
}

#OF2-declineOffer:hover {
  background: #ff6666; /* Change background color on hover */
  color: #000000; /* Change text color on hover */
}

/* Receive Buy Offer Popup */
#OF2-popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #00ff00;
  color: #00ff00;
  font-family: 'bithigh', Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 10001;
}

/* Buy Offer Buttons */
#OF2-popup-container button {
  padding: 10px 20px;
  cursor: pointer;
  width: 45%;
  font-family: 'bithigh', sans-serif;
  border-radius: 5px;
  transition: background 0.3s ease, color 0.3s ease; /* Smooth transition */
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.5); /* Subtle glow effect */
}

#OF2-acceptBuyOffer {
  background: rgba(0, 255, 0, 0.6);
  color: rgba(0, 2, 9, 0.946);
  border-left: 2px solid rgba(0, 255, 0, 0.849);
  border-right: 2px solid rgba(0, 255, 0, 0.849);
  border-top: 2px solid rgba(0, 255, 0, 0.849);
  border-bottom: 3px solid rgba(0, 255, 0, 0.849);
}

#OF2-acceptBuyOffer:hover {
  background: #00ff00; /* Solid green background on hover */
  color: #000000; /* Black text on hover */
}

#OF2-declineBuyOffer {
  background: rgba(255, 0, 0, 0.6);
  color: #fff600;
  border-left: 2px solid rgba(255, 0, 0, 0.86);
  border-right: 2px solid rgba(255, 0, 0, 0.892);
  border-top: 2px solid rgba(255, 0, 0, 0.892);
  border-bottom: 3px solid rgba(255, 0, 0, 0.892);
}

#OF2-declineBuyOffer:hover {
  background: #ff6666; /* Change background color on hover */
  color: #000000; /* Change text color on hover */
}
.OF2-notification {
  position: fixed; bottom: 8%; right: -100%; 
  width: 80%; 
  max-width: 100%; 
  margin-left: auto; 
  margin-right: auto; 
  left: 5%; 
  padding: 20px; border-radius: 5px; border: 2px solid #00ff00; 
  color: #000000; font-family: 'bithigh', Arial, sans-serif; 
  z-index: 10001; text-align: center; 
  transition: right 0.5s ease-out;
  text-align: left;
}
/* BondPop Popup Styles */
#BondPop-popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #00ff00;
  color: #00ff00;
  font-family: 'bithigh', Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 10001;
}

#BondPop-popup-container button {
  padding: 10px 20px;
  cursor: pointer;
  width: 45%;
  font-family: 'bithigh', sans-serif;
  border-radius: 5px;
  transition: background 0.3s ease, color 0.3s ease;
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}

#BondPop-acceptOffer {
  background: rgba(0, 255, 0, 0.6);
  color: rgba(0, 2, 9, 0.946);
  border-left: 2px solid rgba(0, 255, 0, 0.849);
  border-right: 2px solid rgba(0, 255, 0, 0.849);
  border-top: 2px solid rgba(0, 255, 0, 0.849);
  border-bottom: 3px solid rgba(0, 255, 0, 0.849);
}

#BondPop-acceptOffer:hover {
  background: #00ff00;
  color: #000000;
}

#BondPop-declineOffer {
  background: rgba(255, 0, 0, 0.6);
  color: #fff600;
  border-left: 2px solid rgba(255, 0, 0, 0.86);
  border-right: 2px solid rgba(255, 0, 0, 0.892);
  border-top: 2px solid rgba(255, 0, 0, 0.892);
  border-bottom: 3px solid rgba(255, 0, 0, 0.892);
}

#BondPop-declineOffer:hover {
  background: #ff6666;
  color: #000000;
}

#bond-holdings-container {
  display: block;
  margin: 20px;
  border: 4px solid #00ff00;
  padding: 15px;
  background-color: rgba(0, 0, 100, 0.8);
  border-radius: 5px;
  margin-bottom: 20px;
}

#bond-holdings-container h2 {
  color: rgba(1, 251, 244, 0.8);
  text-align: center;
  margin-bottom: 20px;
}

.bond-item {
  border: 2px solid rgba(49, 3, 64, 0.6);
  padding: 10px;
  margin-bottom: 15px;
  font-size: 1rem;
  background-color: rgba(150, 16, 192, 0.804);
  border-radius: 5px;
}

.bond-item span {
  display: block;
  margin: 5px 0;
  padding: 5px;
  color: #00ff00;
}

.bond-item span:nth-child(odd) {
  background-color: rgba(109, 39, 131, 0.804);
}

.bond-item span:nth-child(even) {
  background-color: rgba(1, 57, 251, 0.2);
}

.bond-item span strong {
  width: 130px;
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
  background-color: rgba(87, 1, 251, 0.8);
  padding: 5px;
}

.bond-item span:nth-child(even) strong {
  background-color: rgba(49, 3, 64, 0.6);
}
.bond-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.bond-row {
  display: table-row;
}

.bond-cell {
  display: table-cell;
  padding: 8px 12px;
  vertical-align: middle;
  text-align: left;
  background-color: rgba(109, 39, 131, 0.6); /* Light background for data rows */
}

/* Title row with a darker background */
.bond-title-row .bond-cell {
 /* Slightly darker than data rows */
  color: rgba(253, 254, 0, 0.9); /* Bright yellow for text */
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding: 10px;
  border-radius: 5px 5px 0 0; /* Rounded corners for the title row */
}

/* Strong emphasis for labels */
.bond-label {
  font-weight: bold;
  color: rgba(253, 254, 0, 0.784);
  background-color: rgba(85, 20, 120, 0.8);
}

/* Data row styling */
.bond-data {
  color: rgba(251, 187, 116, 0.858); /* White text for data */
}

/* Hover effect for data rows */
.bond-row:hover .bond-cell {
  background-color: rgba(255, 255, 255, 0.2); /* Slightly lighter on hover */
}

/* Ensure last child aligns properly */
.bond-cell:last-child {
  text-align: right;
}

/* Remove bottom border for the last row */
.bond-row:last-child .bond-cell {
  border-bottom: none;
}

/* Add some hover effects for better interactivity */
.bond-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.01);
  transition: all 0.2s ease-in-out;
}
/* Container for all zollar holdings */
#zollar-container {
  margin-bottom: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

/* List container for zollar holdings */
#zollar-holdings-list {
  background: rgba(8, 7, 58, 0.75); /* Adjusted opacity for consistency */
  width: 90%;
  border: 3px solid rgba(182, 113, 247, 1);
  padding: 15px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Individual zollar item */
.zollar-item {
  position: relative; /* Enable positioning for background video */
  display: flex; /* Enable flex layout */
  flex-direction: row; /* Align items horizontally */
  align-items: center; /* Vertically center items */
  justify-content: space-between; /* Space between text and progress bar */
  margin-bottom: 20px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent overlay */
  border: 2px solid rgba(0, 255, 36, 0.724); /* White border with opacity */
  overflow: hidden; /* Ensure content doesn't spill out */
}

/* Descriptor text */
.zollar-item p {
  position: relative; /* Position relative to stay above the video */
  z-index: 1; /* Ensure the text is above the video */
  margin: 0; /* Remove default margins */
  font-size: 16px; /* Set desired font size */
  flex: 1; /* Allow the descriptor to take up available space */
  text-align: left; /* Align text to the left */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Add a semi-transparent background for better readability */
  padding: 5px 10px; /* Optional: Add padding around the text */
  border-radius: 5px; /* Optional: Rounded corners for the text background */
}

/* Video centered */
.zollar-item video {
  position: absolute; /* Position the video absolutely within .zollar-item */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the container without distortion */
}


/* Vertical progress bar styled as a gauge */
.progress-bar {
  display: flex; /* Enable flex layout */
  flex-direction: column; /* Stack children vertically */
  justify-content: flex-end; /* Align progress-fill to the bottom */
  width: 60px; /* Fixed width for consistency */
  height: 150px; /* Fixed height to resemble a gauge */
  background: linear-gradient(to top, rgba(154, 175, 76, 0.716), rgba(0, 255, 36, 0.724)); /* Gradient for depth */
  border-radius: 5px; /* Rounded edges */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5); /* Inner shadow for depth */
  position: relative; /* For positioning pseudo-elements */
  border: 2px solid rgba(0, 255, 36, 0.724); /* White border for contrast */
  overflow: hidden; /* Clip overflowing content */
  margin-left: 20px; /* Space between progress bar and video */
  flex: 0 0 auto; /* Prevent the progress bar from growing or shrinking */
  z-index: 1; /* Ensure progress bar is above the video */
}

/* Vertical progress fill */
.progress-fill {
  width: 100%;
  min-width: 100%; /* Fill the entire width of the progress bar */
  background: linear-gradient(to top, rgba(53, 55, 255, 0.75), rgba(253, 0, 215, 0.716)); /* Vibrant gradient fill */
  height: 0%; /* Initial height, set dynamically via JavaScript */
  border-radius: 5px 5px 0 0; /* Rounded top edges */
  transition: height 0.5s ease-in-out; /* Smooth transition for height changes */
  align-self: stretch; /* Ensure it stretches to fill the width */
}

/* Decorative ticks on the progress bar */
.progress-bar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
  to bottom,
  transparent,
  transparent 10px,
  rgba(147, 239, 73, 0.712) 10px,
  rgba(114, 255, 0, 0.459) 12px
  ); /* Ticks every 10-12px */
  pointer-events: none; /* Allow clicks through */
}

/* Pointer at the top of the gauge */
.progress-bar::after {
  content: '';
  position: absolute;
  top: -10px; /* Position above the progress bar */
  left: 50%;
  transform: translateX(-50%);
  border-left: 3x solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 5px solid #ff00eb; /* Triangle pointing down */
}

/* Hover effect for progress-fill */
.progress-fill:hover {
  box-shadow: 0 0 10px rgba(255, 255, 0, 0.7); /* Glow effect on hover */
}




/* Relationship slider container */
.relationship-slider {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Label styling for the slider */
.relationship-slider label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

/* Slider input styling */
.range-slider {
  width: 100%;
  margin-bottom: 5px;
  background-color: #d0d0d0;
  height: 8px;
  appearance: none;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s ease;
}

/* Slider thumb styling */
.range-slider::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #ff0075;
  border-radius: 50%;
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background-color: #ff0075;
  border-radius: 50%;
  cursor: pointer;
}

.range-slider:hover {
  background-color: #a0a0a0;
}

/* Descriptor styling */
.relationship-slider span {
  font-style: italic;
  color: #555;
  margin-left: 5px;
}

.video-container-zollar {
   width: 100%;
   max-width: 400px;
   margin: auto;
   overflow: hidden;
}

@media (max-width: 600px) {
  /* Zollar container */
  #zollar-container, #stock-holdings-container, #qr-codes-container {
  width: 95%;
  margin: 10px auto;
  }

  .holding-item, .holding-stocks, .zollar-item, .qr-code-item {
  margin-bottom: 15px; 
  }

  /* Clear any floating or overlapping issues */
  #zollar-container, #stock-holdings-container, #qr-codes-container {
  clear: both;
  }
}
  
/* Additional container for zollar slider inputs */
#zollarInputs {
  margin-top: 20px;
}
#traderGaugeContainer {
  width: 300px;
  height: 70px;
  margin: 15px auto;
  background: linear-gradient(135deg, rgba(0, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
  border: 2px solid rgba(0, 255, 255, 0.7);
  border-radius: 3px;
  box-shadow: 
    0 0 25px rgba(0, 255, 255, 0.5), 
    inset 0 0 10px rgba(0, 255, 255, 0.5);
  position: relative;
  overflow: hidden;
}
.custom-slider-container {
  margin-bottom: 15px;
}

.custom-slider-container label {
  font-weight: bold;
}

.slider-track {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #d0d0d0;
  margin: 10px 0;
  border-radius: 5px;
}

.slider-thumb {
  position: absolute;
  top: -5px;
  width: 20px;
  height: 20px;
  background-color: #ff0075;
  border-radius: 50%;
  cursor: pointer;
  transform: translateX(-50%);
}
/* Slider input styling */
.range-slider {
  width: 100%;
  margin-bottom: 5px;
  background-color: #d0d0d0;
  height: 8px;
  appearance: none;
  -webkit-appearance: none; /* For Safari */
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s ease;
}

/* Slider thumb styling */
.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* For Safari */
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #ff0075;
  border-radius: 50%;
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background-color: #ff0075;
  border-radius: 50%;
  cursor: pointer;
}

/* Modal container */
#bond-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black background with opacity */
}

/* Modal content */
#bond-modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on the design */
  max-width: 500px; /* Max width for responsiveness */
  border-radius: 10px;
}

/* Bond Details Container */
.bond-details {
  color: #0f0; /* Neon green text */
  background-color: #000000d9; /* Semi-transparent black background */
  border: 3px solid #ffa500cc; /* Orange border */
  border-radius: 10px;
  max-width: 99%;
  width: 98%;
  margin: 0 auto;
  padding: 20px;
  font-family: 'bithigh', Arial, sans-serif;
  font-size: 0.7rem;
  box-shadow: 0 0 15px rgba(255, 165, 0, 0.8); /* Outer orange glow */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Title Styling */
.bond-details h2 {
  font-size: 0.8rem;
  color: #ffa500; /* Orange text */
  text-align: center;
  text-shadow: 0 0 10px #ffa500;
  margin-bottom: 20px;
  border-bottom: 2px solid rgba(255, 165, 0, 0.8);
  padding-bottom: 10px;
}

/* Bond Info Section */
.bond-info {
  margin-bottom: 20px;
  font-size: 0.7rem;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border-bottom: 1px solid #ffa500;
}

.info-label {
  font-weight: bold;
  color: #ffa500;
  font-size: 0.7rem;
}

.info-value {
  color: rgba(253, 0, 215, 0.716); /* Neon green for values */
  font-weight: bold;
  font-size: 0.7rem;
}

/* Bid Section */
.bid-section {
  margin-top: 20px;
  text-align: center;
}

.bid-section h3 {
  font-size: 1rem;
  color: rgba(255, 165, 0, 0.9); /* Bright orange */
  text-shadow: 0 0 10px rgba(255, 165, 0, 0.8);
  margin-bottom: 15px;
}

/* Input Field */
.bid-section input[type="number"] {
  padding: 10px;
  font-size: 0.7rem;
  background-color: rgba(0, 40, 80, 0.6); /* Dark blue background */
  border: 2px solid #00ff00; /* Neon green border */
  color: #00ff00;
  border-radius: 5px;
  width: 80%;
  margin-bottom: 15px;
  box-shadow: inset 0 0 5px rgba(0, 255, 0, 0.7); /* Inner glow effect */
}

/* Place Bid Button */
.bid-section button {
  padding: 10px 20px;
  font-size: 0.7rem;
  width: 90%;
  max-width: 95%;
  background-color: rgba(255, 165, 0, 0.8); /* Light orange */
  color: black; /* Black text */
  border: 2px solid rgba(255, 165, 0, 0.9); /* Orange border */
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 15px rgba(255, 165, 0, 0.8);
}

.bid-section button:hover {
  background-color: #ffa500; /* Solid orange */
  box-shadow: 0 0 20px #ffa500; /* Stronger glow */
}

.bid-section button:active {
  transform: scale(0.98);
  box-shadow: 0 0 10px rgba(255, 165, 0, 0.7);
}

.bondBuyCon-overlay {
  display: flex;
  position: fixed;
  z-index: 1001; /* Above most elements, but below critical fixed UI */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85); /* Subtle black overlay */
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.bondBuyCon-content {
  background-color: rgba(8, 7, 58, 0.75); /* Consistent with project input styles */
  color: rgba(97, 0, 102, 0.563); /* Neon green text */
  border: 2px solid #ce00ff; /* Neon green border */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(253, 0, 215, 0.716); /* Subtle green glow */
  width: 90%;
  max-width: 98%;
  width: 95%; /* Prevents it from overwhelming smaller screens */
  text-align: center;
  font-family: 'bithigh', Arial, sans-serif;
}

.bondBuyCon-content p {
  margin-bottom: 20px; /* Space between message and buttons */
  font-size: 1rem; /* Readable text size */
}

.bondBuyCon-content button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  font-size: 1rem;
  font-family: 'bithigh', Arial, sans-serif;
  background-color: rgba(0, 255, 0, 0.7); /* Light green background */
  color: #000000; /* Black text */
  border: 2px solid #00ff00; /* Neon green border */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.7); /* Subtle glow */
}

.bondBuyCon-content button:hover {
  background-color: #00ff00; /* Solid neon green on hover */
  color: #000000;
}

.bondBuyCon-content button:active {
  transform: scale(0.98); /* Slight press effect */
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}
/* Cancel button styles */
.bondBuyCon-cancel {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  font-size: 1rem;
  font-family: 'bithigh', Arial, sans-serif;
  background-color: rgba(255, 48, 48, 0.616); /* Light red background */
  color: #000000; /* Black text */
  border: 2px solid #ff0000; /* Red border */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(255, 48, 48, 0.616); /* Subtle red glow */
}

.bondBuyCon-cancel:active {
  transform: scale(0.98); /* Slight press effect */
  box-shadow: 0 0 5px rgba(255, 48, 48, 0.616);
}

/* Buy button styles */
.buyBondButton {
  display: inline-block;
  padding: 10px 20px;
  width: 80%;
  max-width: 100%;
  margin: 10px;
  font-size: 1rem;
  font-family: 'bithigh', Arial, sans-serif;
  background-color: rgba(255, 221, 0, 0.689); /* Light green background */
  color: #000000; /* Black text */
  border: 2px solid #ce00ff; /* Green border */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(255, 221, 0, 0.689); /* Subtle green glow */
}

.buyBondButton:hover {
  background-color: #00ff00; /* Solid neon green on hover */
  color: #000000;
}

.buyBondButton:active {
  transform: scale(0.98); /* Slight press effect */
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}

.bidBondButton {
  display: inline-block;
  padding: 10px 20px;
  width: 80%;
  max-width: 100%;
  margin: 10px;
  font-size: 1rem;
  font-family: 'bithigh', Arial, sans-serif;
  background-color: rgba(255, 221, 0, 0.689); /* Light green background */
  color: #000000; /* Black text */
  border: 2px solid #ce00ff; /* Green border */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(255, 221, 0, 0.689); /* Subtle green glow */
}

.bidBondButton:hover {
  background-color: #00ff00; /* Solid neon green on hover */
  color: #000000;
}

.bidBondButton:active {
  transform: scale(0.98); /* Slight press effect */
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}

@media screen and (max-width: 768px) {
  .bond-details {
    max-width: 90%;
    padding: 15px;
  }

  .info-row {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .info-label, .info-value {
    width: 100%;
  }

  .bid-section input[type="number"] {
    width: 100%;
  }
}


/* Close button */
.close-modal {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close-modal:hover,
.close-modal:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


/* LBO Popup Styling */
.lbo-popup {
  width: 90%;
  max-width: 600px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(15, 15, 15, 0.95);
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  color: #00ff00;
  font-family: 'bithigh', sans-serif;
  text-align: left;
  box-shadow: 0 0 20px #00ff00, 0 0 40px #00ff00;
}

.lbo-popup h2 {
  color: #00ff00;
  font-size: 24px;
  text-shadow: 0 0 8px #00ff00;
  margin-bottom: 15px;
}

.lbo-popup h3 {
  color: #00ff00;
  font-size: 18px;
  margin: 10px 0;
  text-shadow: 0 0 5px #00ff00;
}

.lbo-popup label {
  display: block;
  padding: 8px 0;
  margin: 5px 0;
  color: #00ff00;
  font-size: 16px;
  background-color: rgba(20, 20, 20, 0.8);
  border-radius: 5px;
  text-shadow: 0 0 3px #00ff00;
  cursor: pointer;
}

.lbo-popup label:hover {
  background-color: rgba(50, 50, 50, 0.8);
}

.lbo-popup input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}

.lbo-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.lbo-buttons .lbo-button {
  padding: 10px 20px;
  background-color: transparent;
  color: #00ff00;
  border: 2px solid #00ff00;
  border-radius: 8px;
  font-family: 'bithigh', sans-serif;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  box-shadow: 0 0 5px #00ff00, 0 0 15px #00ff00;
}

.lbo-buttons .lbo-button:hover {
  background-color: #00ff00;
  color: #000;
  box-shadow: 0 0 20px #00ff00, 0 0 30px #00ff00;
}

/* Close Button Styling */
.close-button-LBO {
  padding: 8px 15px;
  background-color: transparent;
  color: #00ff00;
  border: 2px solid #00ff00;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'bithigh', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.close-button-LBO:hover {
  background-color: #00ff00;
  color: #000;
  box-shadow: 0 0 10px #00ff00, 0 0 20px #00ff00;
}


/* Subsidy Popup Styles */
.subsidy-popup {
  width: 90%;
  max-width: 400px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.85);
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  color: #00ff00;
  font-family: 'bithigh', sans-serif;
}

/* Close Button for Subsidy Popup */
.close-button-subsidy {
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  background-color: #00ff00;
  border: none;
  border-radius: 5px;
  color: #000;
}

/* Additional styles for Subsidy Button */
#SubsidyButton {
  padding: 2px;
  cursor: pointer;
  background-color: rgba(0, 47, 255, 0.672); /* Example color */
  border: none;
  border-radius: 5px;
  color: #d1c;
  font-size: 16px;
}

#SubsidyButton:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.9); /* Gold color */
}

/* Ensure containers expand with content */
.container-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Specific adjustments for smaller screens */
@media (max-width: 600px) {
  .container-flex > div {
    width: 100%;
    margin: 40px 0px 0px 0px;
    overflow: hidden; /* Prevent overflow */
  }
}
/* Bond Table Styles */
/* Full-width cells (like Name and Buy button) */
.bond-row td[data-title="ZBond Name"],
.bond-row td[data-title="ZBond Buy"] {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    color: #fd0;
    background-color: rgba(68, 0, 58, 0.716);
    border-bottom: 1px solid rgba(128, 0, 128, 0.5); /* Purple border */
    margin: 0;
    padding: 5px; /* Tighten padding */
    position: relative; /* Ensures button is properly placed */
}

/* Owner and Maturity cells in the same row */
.bond-row td[data-title="ZBond Owner"],
.bond-row td[data-title="ZBond Maturity"] {
    width: 49%;
    float: left;
    box-sizing: border-box;
    text-align: center;
    padding: 5px; /* Tighten padding */
    font-size: 1rem;
    color: #fd0;
    background-color: rgba(128, 0, 128, 0.2); /* Light purple background */
    border-bottom: 1px solid rgba(128, 0, 128, 0.3);
    margin: 0;
}

/* Price and Interest Rate cells in the same row */
.bond-row td[data-title="ZBond Price"],
.bond-row td[data-title="ZBond Interest Rate"] {
    width: 49%;
    float: right;
    box-sizing: border-box;
    text-align: center;
    padding: 5px; /* Tighten padding */
    font-size: 1rem;
    color: #fd0;
    background-color: rgba(128, 0, 128, 0.2); /* Light purple background */
    border-bottom: 1px solid rgba(128, 0, 128, 0.3);
    margin: 0;
}

/* Buttons in Buy cell */
.bond-row td[data-title="ZBond Buy"] button {
   font-family: bithigh, sans-serif;
    font-size: 1rem;
    padding: 8px 15px; /* Adjust padding for a tighter layout */
    color: #fd0;
    background-color: rgba(147, 0, 255, 0.7); /* Purple button */
    border: 1px solid rgba(255, 221, 0, 0.689);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto; /* Center the button inside the cell */
    position: relative; /* Ensure it doesn't overflow */
    z-index: 2;
    width: 90%;
}

.bond-row td[data-title="ZBond Buy"] button:hover {
  transform: translateZ(0);
    background-color: rgba(147, 0, 255, 1); /* Brighter purple */
}

/* Ensure no overlapping and proper alignment */
.bond-row {
    color: #fd0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    padding: 5px; /* Reduced padding */
    margin: 5px 0;
    border-bottom: 1px solid rgba(128, 0, 128, 0.3);
    height: auto;
}

/* Responsive adjustments: Stacking cells on smaller screens */
@media screen and (max-width: 768px) {
    .bond-row td {
        width: 100%;
        float: none;
    }
}

@media screen and (width <= 2600px) {
    .bond-row td:before {
        content: attr(data-title);
        color: #fd0;
        text-align: center;
        z-index: 1;
        background-color: #1f054a80;
        border-radius: 4px;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 0.9rem;
        font-weight: 700;
        display: block;
        position: relative;
    }
}

/* Session Creation Modal Styles */
.modal-info {
  color: #00ff00;
  font-size: 0.9rem;
  margin-bottom: 20px;
  text-align: left;
  padding: 10px;
  background-color: rgba(0, 255, 0, 0.1);
  border-left: 3px solid #00ff00;
}

.input-group label {
  color: #00ff00;
  display: block;
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.input-group small {
  color: rgba(0, 255, 0, 0.7);
  font-size: 0.8rem;
  display: block;
  margin-top: 5px;
}

.primary-btn, .secondary-btn {
  font-family: 'bithigh', Arial, sans-serif;
  padding: 10px 20px;
  margin: 10px 0;
  width: 100%;
  border: 2px solid;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  letter-spacing: 2px;
  transition: all 0.3s ease;
}

.primary-btn {
  background-color: rgba(0, 255, 0, 0.2);
  color: #00ff00;
  border-color: #00ff00;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
}

.primary-btn:hover {
  background-color: #00ff00;
  color: #000;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.5);
}

.secondary-btn {
  background-color: transparent;
  color: #00ff00;
  border-color: #00ff00;
}

.secondary-btn:hover {
  background-color: rgba(0, 255, 0, 0.1);
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.3);
}
#closeModalButton{
  background-color: rgba(255, 0, 0, 0.7);
}
/* Credentials Modal Styles */
.warning {
  color: #ff0000;
  font-size: 1rem;
  margin: 15px 0;
  padding: 10px;
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
}

  .success {
background: rgba(0, 255, 0, 0.1);
border: 1px solid rgba(0, 255, 0, 0.3);
padding: 1rem;
border-radius: 4px;
margin-top: 1rem;
}

.credentials-box {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #00ff00;
  border-radius: 5px;
  padding: 15px;
  margin: 20px 0;
}

.credential-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  background-color: rgba(0, 255, 0, 0.1);
  border-radius: 3px;
}

.credential-item label {
  color: #00ff00;
  margin-right: 10px;
  font-size: 0.9rem;
}

.credential-item span {
  color: #00ff00;
  font-family: monospace;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 5px 10px;
  border-radius: 3px;
  margin: 0 10px;
  flex-grow: 1;
}

.copy-btn {
  background-color: transparent;
  border: 1px solid #00ff00;
  color: #00ff00;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: all 0.3s ease;
}

.copy-btn:hover {
  background-color: #00ff00;
  color: #000;
}

#qrCodeContainer {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
  margin: 20px auto;
}

#qrCodeContainer canvas {
  display: block;
  margin: 0 auto;
}

/* Modal Animation */
.modal {
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    margin: 10% auto;
    padding: 15px;
  }

  .credential-item {
    flex-direction: column;
    align-items: stretch;
  }

  .credential-item span {
    margin: 5px 0;
  }

  .copy-btn {
    margin-top: 5px;
  }
}


/* First Login Modal */
/**************************************
 * BASE STYLES (KEPT AS-IS)
 **************************************/
#firstLoginModal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  font-family: 'bithigh';
}

#firstLoginModal .modal-content {
  background-color: rgba(0, 40, 0, 0.95);
  margin: 5% auto;
  padding: 15px;
  border: 2px solid #00ff00;
  width: 90%;
  max-width: 600px;
  position: relative;
  color: #00ff00;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.3);
  height: auto; /* Allow dynamic content scaling */
  max-height: 95vh;
  overflow-y: auto;
}

#firstLoginModal h2 {
  color: #00ff00;
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.6rem;
}

.compSerCla {
  font-size: 1.4rem;
  padding: 15px;
  text-align: center;
}

.search-label {
  font-size: 1rem;
  color: rgba(253, 254, 0, 0.932);
  font-weight: bold;
}

.search-helper {
  font-size: 0.85rem;
  color: rgba(61, 210, 2, 0.729);
  margin-bottom: 1em;
}

#firstLoginModal .input-group {
  margin-bottom: 10px;
}

#firstLoginModal input,
#firstLoginModal textarea {
  width: 100%;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid #00ff00;
  color: #00ff00;
  font-family: 'bithigh';
  font-size: 1rem;
}

#companyDescription {
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #00ff00;
  margin: 10px 0px;
  color: #00ff00;
  height: 60px;
}

/* Keep "Complete Setup" button consistent */
#firstLoginModal #completeProfileBtn {
  width: 100%;
  padding: 12px;
  background-color: rgba(0, 255, 0, 0.3);
  color: #00ff00;
  border: 2px solid #00ff00;
  cursor: pointer;
  margin-top: 15px;
  font-family: 'bithigh';
  font-size: 1rem;
  transition: all 0.3s ease;
}

#firstLoginModal #completeProfileBtn:hover {
  background-color: rgba(0, 255, 0, 0.5);
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

#firstLoginModal .search-results {
  max-height: 250px;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #00ff00;
  margin-top: 5px;
  padding: 5px;
}

.value-input {
  width: 100%;
  padding: 10px;
  border: 2px solid #444;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.company-result {
  background: rgba(20, 20, 20, 0.95);
  border: 1px solid #444;
  border-radius: 6px;
  padding: 12px;
  margin: 8px 0;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

.company-result:hover {
  background: rgba(40, 40, 40, 0.95);
  transform: translateY(-2px);
}

.company-result h4 {
  color: #0f1;
  margin-bottom: 5px;
  font-size: 1rem;
}

.company-result p {
  color: #8f8;
  margin: 0;
  font-size: 0.85rem;
}

/**************************************
 * RESPONSIVE ADJUSTMENTS FOR MOBILES
 **************************************/
@media (max-width: 768px) {
  #firstLoginModal .modal-content {
    margin: 8% auto;
    width: 95%;
    height: auto;
    max-height: 90vh;
  }

  #firstLoginModal h2 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }

  .compSerCla {
    font-size: 1.2rem;
    padding: 12px;
  }

  #firstLoginModal input,
  #firstLoginModal textarea {
    font-size: 0.95rem;
  }

  #firstLoginModal #completeProfileBtn {
    padding: 10px;
    font-size: 0.95rem;
  }

  .company-result {
    padding: 10px;
    font-size: 0.85rem;
  }

  .company-result h4 {
    font-size: 0.9rem;
  }

  .company-result p {
    font-size: 0.8rem;
  }

  .search-results {
    max-height: 200px;
  }

  .company-selection {
    position: relative;
    margin-top: 15px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  #firstLoginModal .modal-content {
    margin: 4% auto;
    width: 95%;
    padding: 10px;
  }

  #firstLoginModal h2 {
    font-size: 1.1rem;
    margin-bottom: 8px;
  }

  .compSerCla {
    font-size: 1rem;
    padding: 10px;
  }

  #firstLoginModal input,
  #firstLoginModal textarea {
    font-size: 0.9rem;
  }

  #firstLoginModal #completeProfileBtn {
    padding: 8px;
    font-size: 0.9rem;
  }

  .company-result {
    padding: 8px;
    font-size: 0.8rem;
  }

  .company-result h4 {
    font-size: 0.85rem;
  }

  .company-result p {
    font-size: 0.75rem;
  }

  .search-results {
    max-height: 150px;
  }

  .company-selection {
    margin-top: 10px;
    padding: 8px;
  }
}
.titComSel1{
  text-align: left; 
  font-size: 0.8rem;
  padding: 5px;
}
.conversation-step{
  padding: 5px;
  align-content: flex-start;
  font-size: 0.8rem;
  font-family: bithigh, sans-serif;

}
.conversation-step p {
  color: rgba(254, 224, 0, 0.892);
  }
    #searchButton {
  height: 34px; 
  font-size: 14px;
  cursor: pointer;
  background: rgba(253, 254, 0, 0.649);
  border: 1px solid rgba(255, 135, 0, 0.768);
  border-radius: 6px;

}